import axios from "axios";
import {getAxiosAPINew, postAxiosAPI} from "../../../helpers/axios_helpers";
import qs from 'qs';
import { getCookie } from 'react-use-cookie';

export const cabinetAPI = {
    sendToSupport,
    getUserInfo,
    getStorgeData,
    findUserInfo,
    approveUserData,
}

async function getUserInfo() {
    const resp =  await axios({
        ...getAxiosAPINew,
        url: getAxiosAPINew.url + 'users/i/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    return resp
}

async function getStorgeData() {
    const resp =  await axios({
        ...getAxiosAPINew,
        url: getAxiosAPINew.url + 'storge_list/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    return resp
}

async function sendToSupport(subject, message) {
    const resp =  await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'user_messages/messages/',
        data: qs.stringify({'text': message, 'subject': subject}),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    console.log('sendToSupport: ', resp)
    return resp
}

async function findUserInfo(searchParams) {
    const resp =  await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'accounts/search_user/',
        data: qs.stringify(searchParams),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    return resp
}

async function approveUserData(delay) {
    const resp =  await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'accounts/accept_user_info/',
        data: qs.stringify({"delay": delay}),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    return resp
}