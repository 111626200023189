import React from "react";
import "./layout_Page.css";
import { Logo } from "../ui/Logo/Logo";
import Menu from "../ui/Menu/Menu";
import {connect} from "react-redux";
import {openManagerCabinet} from "./services/index/index_actions";
import {closePopupError, closePopupMessage, logout, openCabinet} from "./services/cabinet/cabinet_actions";
import PopupWindow from "../ui/PopupWindow/PopupWindow";
import PopupError from "../ui/PopupError/PopupError";


const topMenuItems = {
    item1: {
        icon: "person",
        route: "/private-cabinet",
        text: "Личный кабинет",
        show: true,
        onClick: null
    },
    item2: {
        icon: "door",
        text: "Выйти",
        show: true,
        onClick: null
    },
};

/**
 * @typedef PropType
 * @property {JSX.Element | string} children
 */

/**
 * @param {PropType} props
 * @returns {JSX.Element}
 * @constructor
 */

function LayoutPage (props) {
    const YEAR = new Date().getFullYear()
    const topMenu = {...topMenuItems};
    topMenu.item1.text = props.user.full_name
    topMenu.item2.onClick = props.logout
    topMenu.item1.onClick = props.openCabinet

    return (
        <div className="Page">
            {props.showPopupWindow && <PopupWindow
            content={props.popupMessage}
            handleClose={props.closePopupMessage}
            />}
            {props.showPopupError && <PopupError
                content={props.errorMessage}
                handleClose={props.closePopupError}
            />}
            <div className="PageSidebar">
                <Logo style={{ position: "relative", left: "-13px" }} />
                <Menu kind="vertical" />
                <div className="CopyrightText AdditionalInfoText">
                    &#169; Дельфин-онлайн, {YEAR}
                </div>
            </div>
            <div className="PageMain">
                <div className="PageTopMenu">
                    <div className="PageTitle">
                        {props.cabinetTitle} <span className="triangle">►</span>
                    </div>
                    <Menu items={topMenu} kind="horizontal" />
                </div>
                <div className="PageContent">{props.children}</div>
            </div>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        user: state.index.userInfo,
        cabinetTitle: state.cabinet.cabinetTitle,
        error: state.cabinet.error,
        showPopupWindow: state.cabinet.showPopupWindow,
        showPopupError: state.cabinet.showPopupError,
        popupMessage: state.cabinet.popupMessage,
        errorMessage: state.cabinet.errorMessage,
        showError: state.cabinet.showError,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        openManagerCabinet: () => dispatch(openManagerCabinet()),
        logout: () => dispatch(logout()),
        openCabinet: () => dispatch(openCabinet()),
        closePopupMessage: () => dispatch(closePopupMessage()),
        closePopupError: () => dispatch(closePopupError()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);