import React, { useCallback, useState } from "react";
import "./ResultBox.module.css";

/**
 * @typedef ResultBoxPropsType
 * @property {JSX.Element | string} children
 * @property {JSX.Element | string} footerItems
 * @property {string} expandTitle
 * @property {string} collapseTitle
 */

/**
 *
 * @param {ResultBoxPropsType} props
 * @returns {JSX.Element}
 */

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ResultBox = ({
  children,
  headerItems = "",
  footerItems = "",
  expandTitle,
  collapseTitle,
  onClick,
  notExpanded,
}) => {
  const [expanded, setExpanded] = useState((!notExpanded));
  const onHeaderClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const triangleRight = <span className="triangle">►</span>;
  const triangleDown = <span className="triangle">▼</span>;

  const header = expandTitle ? (
    <div
      onClick={onHeaderClick}
      className={`ResultBoxHeader${expanded ? "" : " _collapsed"}`}
    >
      {expanded ? (
        <React.Fragment>
          {collapseTitle}
          {triangleDown}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {expandTitle}
          {triangleRight}
        </React.Fragment>
      )}
    </div>
  ) : null;

  return (
    <div className="ResultBox" onClick={onClick}>
      {header}
      {expanded ? (
        <div>
          <div className="ResultBoxHeaderItem">{headerItems}</div>
          {children}
          <div className="ResultBoxFooter">{footerItems}</div>
        </div>
      ) : null}
    </div>
  );
};
