export const Types = {
    SEND_REQUEST: 'SEND_REQUEST',
    SEND_REQUEST_DETAIL: 'SEND_REQUEST_DETAIL',
    REALTY_SEARCH: 'REALTY_SEARCH',
    SAVE_REQUEST_START: 'SAVE_REQUEST_START',
    SAVE_REQUEST_SUCCESS: 'SAVE_REQUEST_SUCCESS',
    SAVE_REQUEST_ERROR: 'SAVE_REQUEST_ERROR',
    CLOSE_REALTY_DETAIL: 'CLOSE_REALTY_DETAIL',
    SHOW_REALTY_DETAIL_START: 'SHOW_REALTY_DETAIL_START',
    SHOW_REALTY_DETAIL_SUCCESS: 'SHOW_REALTY_DETAIL_SUCCESS',
    SHOW_REALTY_DETAIL_ERROR: 'SHOW_REALTY_DETAIL_ERROR',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SEARCH_ERROR: 'SEARCH_ERROR',

    ADD_IN_REALTY_STORGE_LIST: 'ADD_IN_REALTY_STORGE_LIST',
    ADD_IN_REALTY_STORGE_LIST_SUCCESS: 'ADD_IN_REALTY_STORGE_LIST_SUCCESS',
    ADD_IN_REALTY_STORGE_LIST_ERROR: 'ADD_IN_REALTY_STORGE_LIST_ERROR',
    DELETE_FROM_REALTY_STORGE_LIST: 'DELETE_FROM_REALTY_STORGE_LIST',
    DELETE_FROM_REALTY_STORGE_LIST_SUCCESS: 'DELETE_FROM_REALTY_STORGE_LIST_SUCCESS',
    DELETE_FROM_REALTY_STORGE_LIST_ERROR: 'DELETE_FROM_REALTY_STORGE_LIST_ERROR',
}