import axios from "axios";
import {deleteAxiosAPI, getAxiosAPI, postAxiosAPI} from "../../../helpers/axios_helpers";
import qs from 'qs';
import { getCookie } from 'react-use-cookie';


export const jantarAPI = {
    search: searchRequest,
    getDetail: getDetailRequest,
    saveRequest,
    addInStorgeList,
    deleteFromStorgeList,
}



async function searchRequest(searchParams) {
    let params = '?';
    for (const [key, value] of Object.entries(searchParams)) {
        params = params + `${key}=${value}&`
    }
    params = params.slice(0,-1);
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'amber/phone_number/' + params,
        // data: qs.stringify(params),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function saveRequest(id) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'transport/search/',
        data: qs.stringify({
            'request_object_id': id
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function getDetailRequest(id){
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'transport/'+ id + '/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function addInStorgeList(entityID) {
    console.log('API entity id: ', entityID)
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'transport/tracked_list/',
        data: qs.stringify({
            'tracked_object': entityID
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function deleteFromStorgeList(entityID) {
    const resp = await axios({
        ...deleteAxiosAPI,
        url: deleteAxiosAPI.url + `transport/tracked_list/${entityID}/`,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}