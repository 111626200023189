import {fork, takeLatest, call} from 'redux-saga/effects';

import {
  GET_MORE_INFO
} from '../constants';

import { getMoreInfo } from './moreInfoUser';
import {indexSagas} from "../../components/pages/services/index/index_sagas";
import {authSagas} from "../../components/pages/services/auth_saga";
import {cabinetSagas} from "../../components/pages/services/cabinet/cabinet_saga";
import {realty_Sagas} from "../../components/cabinets/Realty/services/realty_sagas";
import {sbsSagas} from "../../components/cabinets/SBS/services/sbs_sagas";
import {transportSagas} from "../../components/cabinets/Transport/services/transport_sagas";
import {rfpiSagas} from "../../components/cabinets/RFPI/service/sagas";
import {autozalogSagas} from "../../components/cabinets/Autozalog/services/sagas";
import {jantarSagas} from "../../components/cabinets/Jantar/services/sagas";
import {checksSagas} from "../../components/cabinets/Checks/services/sagas";
import {botClientSagas} from "../../components/cabinets/BotClient/services/saga";

const dolphine = `
                               _.-~  )
                    _..--~~~~,'   ,-/     _
                 .-'. . . .'   ,-','    ,' )
               ,'. . . _   ,--~,-'__..-'  ,'
             ,'. . .  (@)' ---~~~~      ,'
            /. . . . '~~             ,-'
           /. . . . .             ,-'
          ; . . . .  - .        ,'
         : . . . .       _     /
        . . . . .          '-.:
       . . . ./  - .          )
      .  . . |  _____..---.._/ _____
~---~~~~----~~~~             ~~`;

function* startup() {
  try {
    yield call(console.log, dolphine);
  } catch (error) {
    console.log('error')
  }
}

export function* rootSaga() {
  yield fork(startup);
  yield fork(indexSagas);
  yield fork(authSagas);
  yield fork(cabinetSagas);
  yield fork(realty_Sagas);
  yield fork(sbsSagas);
  yield fork(transportSagas);
  yield fork(rfpiSagas);
  yield fork(autozalogSagas);
  yield fork(jantarSagas);
  yield fork(checksSagas);
  yield fork(botClientSagas);
  yield takeLatest(GET_MORE_INFO, getMoreInfo);
}
