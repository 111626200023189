import {Types} from "./action_types";


function showDetailForm(id) {
    return {
        type: Types.SEND_JANTAR_REQUEST_DETAIL,
        id
    }
}

function sendSearchRequest(searchParams){

    return {
        type: Types.JANTAR_SEARCH,
        searchParams,
    }
}
function closeDetail(){
    return {
        type: Types.CLOSE_JANTAR_DETAIL,
    }
}

const addInStorgeList = (id) => ({type: Types.ADD_IN_JANTAR_STORGE_LIST, id});
const deleteFromStorgeList = (id) => ({type: Types.DELETE_FROM_JANTAR_STORGE_LIST, id});


export const jantarActions = {
    showDetail: showDetailForm,
    search: sendSearchRequest,
    closeDetail: closeDetail,
    addInStorgeList,
    deleteFromStorgeList,
}

