import {
    ADD_SBS_COMMENT_ERROR,
    ADD_SBS_COMMENT_SUCCESS,
    CLOSE_SBS_DETAIL,
    SBS_SEARCH_ERROR,
    SBS_SEARCH_START,
    SBS_SEARCH_SUCCESS,
    SHOW_SBS_AMOUNTS_ERROR,
    SHOW_SBS_AMOUNTS_START,
    SHOW_SBS_AMOUNTS_SUCCESS, SHOW_SBS_COMMENTS_ERROR, SHOW_SBS_COMMENTS_START, SHOW_SBS_COMMENTS_SUCCESS,
    SHOW_SBS_DETAIL_ERROR,
    SHOW_SBS_DETAIL_START,
    SHOW_SBS_DETAIL_SUCCESS,
    SHOW_SBS_REQUESTS_ERROR,
    SHOW_SBS_REQUESTS_START,
    SHOW_SBS_REQUESTS_SUCCESS

} from "./sbs_action_types";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";



const initialState = {
    showError: false,
    showPopup: false,
    popupMessage: '',
    error: null,
    showDetail: false,
    loading: false,
    waitAmounts: false,
    waitRequests: false,
    waitComments: false,

    foundObjects: null,
    sbsObjectData: null,
    amounts: null,
    requests: null,
    comments: null,
    foundPage: null,
    maxFoundPage: null,

    searchQuery: null,
    storgeList: null,
}

const handlers = {
    [SHOW_SBS_DETAIL_START]: state =>({...state, error: null}),
    [SHOW_SBS_DETAIL_SUCCESS]: (state, action)=>({...state, sbsObjectData: action.sbsObjectData, showDetail: true}),
    [SHOW_SBS_DETAIL_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [SHOW_SBS_AMOUNTS_START]: state =>({...state, error: null, waitAmounts: true}),
    [SHOW_SBS_AMOUNTS_SUCCESS]: (state, action)=>({...state, amounts: action.amounts, waitAmounts: false}),
    [SHOW_SBS_AMOUNTS_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [SHOW_SBS_REQUESTS_START]: state =>({...state, error: null}),
    [SHOW_SBS_REQUESTS_SUCCESS]: (state, action)=>({...state, requests: action.requests}),
    [SHOW_SBS_REQUESTS_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [SHOW_SBS_COMMENTS_START]: state =>({...state, error: null}),
    [SHOW_SBS_COMMENTS_SUCCESS]: (state, action)=>({...state, comments: action.comments}),
    [SHOW_SBS_COMMENTS_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [ADD_SBS_COMMENT_SUCCESS]: (state, action)=>({...state, error: null}),
    [ADD_SBS_COMMENT_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),

    [CLOSE_SBS_DETAIL]: (state, action)=>({...state, showDetail: false}),
    [SBS_SEARCH_START]: state =>({...state, loading: true, error: null}),
    [SBS_SEARCH_SUCCESS]: (state, action)=>({...state, loading: false, foundObjects: action.foundObjects}),
    [SBS_SEARCH_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),

    [cabTypes.GET_STORGE_LIST_SUCCESS]: (state, action)=>({...state, storgeList: action.sbsIDs}),

    
    DEFAULT: state => state
}


export function sbsReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}