export const SEARCH_MODES =  {
    'GOSZNAK': {
        searchLabel: 'Гос. рег. знак',
        fields:[{
            name: 'gos_reg_znak',
            placeholder: 'A555AA777',
            title: 'Гос. рег. знак',
            typeInput: 'text',
            required: true,
            value: '',
        }],
    },
    'VIN': {
        searchLabel: 'VIN',
        fields: [{
            name: 'vin',
            placeholder: 'VIN',
            title: 'VIN',
            typeInput: 'text',
            required: true,
            value: '',
        }]
    },
    'PTS':{
        searchLabel: 'ПТС',
        fields:[{
            name: 'pts',
            placeholder: 'Номер ПТС',
            title: 'Номер ПТС',
            typeInput: 'text',
            required: true,
            value: '',
        }],
    },
    'BODY': {
        searchLabel: 'Номер кузова',
        fields: [{
            name: 'body_number',
            placeholder: 'Номер кузова',
            title: 'Номер',
            typeInput: 'text',
            required: true,
            value: '',
        },]
    },
    'FIO': {
        searchLabel: 'ФИО собственника',
            fields: [{
            name: 'last_name',
            placeholder: 'Фамилия',
            title: 'Фамилия',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'first_name',
            placeholder: 'Имя',
            title: 'Имя',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'middle_name',
            placeholder: 'Отчество',
            title: 'Отчество',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'birth_date',
            placeholder: '01.01.2000',
            title: 'Дата рождения',
            typeInput: 'date',
            required: true,
            value: '',
                }
        ]
    }
}