import React from 'react';
import {connect} from "react-redux";

import AutozalogSearchForm from './autozalog_search';
import {autozalogActions} from "./services/actions";
import AutozalogDetails from "./autozalog_detail";


class Autozalog extends React.Component {
    renderSearchTab = () => {
        if (this.props.showDetail) {
            //TODO передать статус объекта
            return <AutozalogDetails
                onClose={this.props.closeDetail}
                objectData={this.props.objectData}
            />;
        } else {
            return <AutozalogSearchForm />;
        }
    }


    render() {
        return (this.renderSearchTab())
    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.autozalog.showDetail,
        objectData: state.autozalog.objectData,
        foundObjects: state.autozalog.foundObjects,
        // storgeList: state.autozalog.storgeList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(autozalogActions.closeDetail()),
        // addInStorgeList: (id) => dispatch(autozalogActions.addInStorgeList(id)),
        // deleteFromStorgeList: (id) => dispatch(autozalogActions.deleteFromStorgeList(id)),
        //TODO getPDF()
        //TODO
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Autozalog);