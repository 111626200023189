import {
    Types
} from "./action_types";


const initialState = {
    showDetail: false,
    objectData: null,
    foundObjects: null,
    sendRequest: false,
    loading: false,
    error: null,
    searchParams: null,
    storgeList: null,
    updateSearch: false,
}


const handlers = {
    [Types.SAVE_JANTAR_REQUEST_START]: (state, action) =>({...state, loading: false}),
    [Types.SAVE_JANTAR_REQUEST_SUCCESS]: (state, action)=>({...state, loading: false}),
    [Types.SEND_JANTAR_REQUEST]: (state, action) =>({...state, loading: true, foundObjects: null}),

    [Types.SHOW_JANTAR_DETAIL_START]: (state, action)=>({...state, showError:false, error: action.error}),
    [Types.SHOW_JANTAR_DETAIL_SUCCESS]: (state, action) =>({...state, loading: false, showDetail: true, objectData: action.objectData}),
    [Types.SHOW_JANTAR_DETAIL_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [Types.CLOSE_JANTAR_DETAIL]: (state, action)=>({...state, showDetail: false}),

    [Types.SEARCH_JANTAR_START]: (state, action) =>({...state, loading: true, error: null}),
    [Types.SEARCH_JANTAR_SUCCESS]: (state, action)=>({...state, loading: false, foundObjects: action.foundObjects}),
    [Types.SEARCH_JANTAR_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),


    DEFAULT: state => state
}

export function jantarReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}