import {CHECK_CODE_START, GET_TOKEN} from "./auth_action_types";


export function getToken(login, password) {
    return {
        type: GET_TOKEN,
        login,
        password,
    }
}

export function checkCode(code, retryCount) {
    return {
        type: CHECK_CODE_START,
        code,
        retryCount,
    }
}
