import {call, put, takeEvery} from "redux-saga/effects";
import {
    Types
} from "./action_types";
import {isDebug} from "../../../../constants/config";
import {autozalogAPI} from "./api";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";


function* searchSaga(action) {
    yield put({
        type: Types.SEARCH_AUTOZALOG_START,
    })
    try {
        const resp = yield call(autozalogAPI.search, action.searchParams);

        yield put({
            type: Types.SEARCH_AUTOZALOG_SUCCESS,
            foundObjects: resp.data,
        });
    } catch(error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + message
        });
        yield put({
            type: Types.SEARCH_AUTOZALOG_ERROR,
            error: error
        });
    }
}

function* getTransportDetail(action) {
    yield put({
        type: Types.SHOW_AUTOZALOG_DETAIL_START,
    })
    try {
        const resp = yield call(autozalogAPI.getDetail, action.id)

        yield put({
            type: Types.SHOW_AUTOZALOG_DETAIL_SUCCESS,
            objectData: resp.data
        })
    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения данных объекта. ' + message
        });
        yield put({
            type: Types.SHOW_AUTOZALOG_DETAIL_ERROR,
            error: error
        });

    }
}

function* saveRequest(action) {
    yield put({
        type: Types.SAVE_AUTOZALOG_REQUEST_START,
    })
    try{
        const resp = yield call(autozalogAPI.saveRequest, action.id)
        if (isDebug) {
            console.log(resp.statusText)
        }
        yield put({
            type: Types.SAVE_AUTOZALOG_REQUEST_SUCCESS,
        });
    }catch(error){
        yield put({
            type: Types.SAVE_AUTOZALOG_REQUEST_ERROR,
            error: error
        });
    }
}

function* addInStorgeListsSaga(action) {

    try{
        yield call(autozalogAPI.addInStorgeList, action.id)
        yield put({
            type: Types.ADD_IN_AUTOZALOG_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){

        yield put({
            type: Types.ADD_IN_AUTOZALOG_STORGE_LIST_ERROR,
            error: error
        });
    }
}

function* deleteFromStorgeListsSaga(action) {

    try{
        yield call(autozalogAPI.deleteFromStorgeList, action.id)
        yield put({
            type: Types.DELETE_FROM_AUTOZALOG_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        yield put({
            type: Types.DELETE_FROM_AUTOZALOG_STORGE_LIST_ERROR,
            error: error
        });
    }
}

function* getRequestsSaga(action) {
    yield put({
        type: Types.SHOW_AUTOZALOG_REQUESTS_START,
    })
    try{
        const resp = yield call(autozalogAPI.getRequests, action.id)
        yield put({
            type: Types.SHOW_AUTOZALOG_REQUESTS_SUCCESS,
            requests: resp.data
        });
    }catch(error){
        yield put({
            type: Types.SHOW_AUTOZALOG_REQUESTS_ERROR,
            error: error
        });
    }
}

function* getCommentsSaga(action) {
    yield put({
        type: Types.SHOW_AUTOZALOG_COMMENTS_START,
    })
    try{
        const resp = yield call(autozalogAPI.getComments, action.id)
        yield put({
            type: Types.SHOW_AUTOZALOG_COMMENTS_SUCCESS,
            comments: resp.data
        });
    }catch(error){
        yield put({
            type: Types.SHOW_AUTOZALOG_COMMENTS_ERROR,
            error: error
        });
    }
}

function* postCommentSaga(action) {
    let sleep = ms => new Promise(res=>setTimeout(res,ms));
    try{
        yield call(autozalogAPI.postComment, action.id, action.comment);
        sleep(2000);
        yield put({
            type: Types.SHOW_AUTOZALOG_COMMENTS,
        })
    }catch(error){
        yield put({
            type: Types.ADD_AUTOZALOG_COMMENT_ERROR,
            error: error
        });
    }
}

export function* autozalogSagas() {
    yield takeEvery(Types.AUTOZALOG_SEARCH, searchSaga);
    yield takeEvery(Types.SEND_AUTOZALOG_REQUEST_DETAIL, getTransportDetail);
    yield takeEvery(Types.SHOW_AUTOZALOG_DETAIL_SUCCESS, saveRequest);
    yield takeEvery(Types.ADD_IN_AUTOZALOG_STORGE_LIST, addInStorgeListsSaga);
    yield takeEvery(Types.DELETE_FROM_AUTOZALOG_STORGE_LIST, deleteFromStorgeListsSaga);
    yield takeEvery(Types.SHOW_AUTOZALOG_REQUESTS, getRequestsSaga);
    yield takeEvery(Types.SHOW_AUTOZALOG_COMMENTS, getCommentsSaga);
    yield takeEvery(Types.ADD_AUTOZALOG_COMMENTS, postCommentSaga);
}