export function formatDate(date) {
    if (typeof (date) === "string") {
        const newDate = new Date(date);
        var dd = newDate.getDate();
        if (dd < 10) dd = '0' + dd;

        var mm = newDate.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        var yy = newDate.getFullYear();
        return dd + '.' + mm + '.' + yy;
    }
    return ''
}