import React, {useCallback, useState} from "react";
import "../../ui/Form.css";
import { MainTitle } from "../../ui/Typography/Typography";
import Select from "react-select";
import { Button } from "../../ui/Button/Button";
import { Input } from "../../ui/Input/Input";
import {Textarea} from "../../ui/Textarea/Textarea";



function getOptions(searchModes) {
    let options = [];

    Object.entries(searchModes).forEach(([key, value])=>{
        options.push({value: key, label: value.searchLabel})
    })
    return options
}


const SearchFields = (props) => {

    return (
        <React.Fragment>
        <fieldset className="FormFieldSet">
        {props.filedList.map((item, idx) =>
            <Input
                key={`key_${idx}`}
                placeholder={item.placeholder} 
                required={item.required} 
                type={item.typeInput}
                onChange={props.onChange}
                name={item.name}
                value={props.values[item.name]}
            />
        )}
        </fieldset>
            <fieldset className="FormFieldSet _wide">
                <Textarea
                    placeholder={'Причина запроса'}
                    required={true}
                    onChange={props.onChange}
                    name={'search_reason'}
                    value={props.values['search_reason']}
                />
            </fieldset>
        </React.Fragment>
    );
};

const createStartValues = (fields) => {
    const values = {}
    fields.map((field) => (values[field.name] = ''))
    values['search_reason'] = ''
    return values
}

export function SearchPanel(props) {
    const options = getOptions(props.searchModes)
    const [selectedMode, setSelectedMode] = useState(options[0] || null);
    const [values, setValues] = useState(createStartValues(props.searchModes[props.selectedMode].fields));



    const onValueChange = useCallback(
        (e) => {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            });
            props.onChange(e);
        },
        [values]
    );


    const onModeChange = useCallback(
        (e) => {
            setSelectedMode(e);
            setValues(createStartValues(props.searchModes[e.value].fields));
            props.setSelectedMode(e.value);
        },
        [selectedMode]
    );

    const Submit = (e) => {
        e.preventDefault();
        props.onSubmit();
    }

    return (
        <React.Fragment>
            <MainTitle>Поиск по базе</MainTitle>
            <form className="Form" onSubmit={Submit}>
                <fieldset className="FormFieldSet">
                <Select
                    defaultValue={options[0]}
                    onChange={onModeChange}
                    options={options}
                />
                </fieldset>
                <SearchFields
                    filedList={props.searchModes[props.selectedMode].fields}
                    onChange={onValueChange}
                    searchModes={props.searchModes}
                    values={values}
                />
                {props.children}
                <footer className="FormFooter">
                <Button type="submit">поиск</Button>
                </footer>
            </form>
      </React.Fragment>
    )
}