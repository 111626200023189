export function isInStorgeList(id, storgeList) {
    return storgeList.includes(id)
}

export function getStorageId(storgeList) {
    let ids = {}
    for (let key in storgeList) {
        ids[key]= []
        if (key === 'total_count') {
            continue
        }
        for (let item of storgeList[key]) {
            ids[key].push(item.object_id)
        }
    }
    console.log('storgeList: ', ids)
    return ids
}
