import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import {rootSaga} from '../sagas/rootSagas';
import rootReducer from '../reducers';



const composeEnhancers = (process.env.NODE_ENV !=="production") && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;



export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [sagaMiddleware,];

    const enhancers = composeEnhancers(
        applyMiddleware(...middleware),
    );

    const store = createStore(
        rootReducer,
        enhancers,
    );
    sagaMiddleware.run(rootSaga);
    return store;
}
