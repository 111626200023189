import {
    ASK_CODE_ERROR,
    ASK_CODE_START,
    ASK_CODE_SUCCESS, CHECK_CODE_ERROR, CHECK_CODE_RETRY, CHECK_CODE_START, CHECK_CODE_SUCCESS,
    GET_TOKEN,
    GET_TOKEN_ERROR,
    GET_TOKEN_SUCCESS
} from "./auth_action_types";
import {cabTypes} from "./cabinet/cabinet_action_types";


const initialState = {
    showCodeConfirm: false,
    showError: false,
    error: null,
    userInfo: null,
    retryCount: 3,
}

const handlers = {
    [GET_TOKEN]: state =>({...state}),
    [GET_TOKEN_SUCCESS]: state =>({...state}),
    [GET_TOKEN_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [ASK_CODE_START]: state =>({...state, error: null}),
    [ASK_CODE_SUCCESS]: (state, action)=>({...state, showCodeConfirm: true}),
    [ASK_CODE_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [CHECK_CODE_START]: state =>({...state, error: null}),
    [CHECK_CODE_SUCCESS]: (state, action)=>({...state, showCodeConfirm: false, retryCount: 3}),
    [cabTypes.GET_USER_INFO_SUCCESS]: (state, action)=>({...state}),
    [CHECK_CODE_RETRY]: (state, action)=>({...state, showError:true, error: action.error, retryCount: action.retryCount}),
    [CHECK_CODE_ERROR]: (state, action)=>({...state, showCodeConfirm: false, showError:false, error: action.error, retryCount: 3}),

    DEFAULT: state => state
}

export function authReducer(state=initialState, action) {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}