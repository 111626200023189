import React from 'react';
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText} from "../../ui/Typography/Typography";

function convertDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}

const SearchItem = props => {
    return (
        <ResultBox onClick={()=>props.onClick(props.object.id)}>
            <FieldValueText field="ФИО правообладателя: " value={props.object.person.full_name}/>
            <FieldValueText field="Дата рождения: " value={convertDate(props.object.person.birth_date)}/>
            <FieldValueText field="Дата проверки: " value={convertDate(props.object.date_check)} />
        </ResultBox>


    );
}



export default SearchItem;
