import {call, put, takeEvery} from "redux-saga/effects";

import {isDebug} from "../../../../constants/config";
import {Types} from "./action_types";
import {botApi} from "./api";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";

function* getClientSaga(action) {
    try {
        const resp = yield call(botApi.getClient);
        if (resp.data.session_uuid) {
            yield put({
                type: Types.GET_CLIENT_SUCCESS,
                botSessionUUID: resp.data.session_uuid,
            });

        } else {
            yield put({
                type: cabTypes.SHOW_POPUP_ERROR,
                message: resp.data.error
            });
        }
    } catch(error) {
        yield put({
            type: Types.CLIENT_IS_BUSY,
            error: error
        });
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + error
        });
    }
}


export function* botClientSagas() {
    yield takeEvery(Types.GET_CLIENT, getClientSaga)
}