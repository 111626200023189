import {checksTypes} from "./action_types";


function showDetailForm(id) {
    return {
        type: checksTypes.SEND_CHECKS_REQUEST_DETAIL,
        id
    }
}

function sendSearchRequest(searchParams){

    return {
        type: checksTypes.CHECKS_SEARCH,
        searchParams,
    }
}
function closeDetail(){
    return {
        type: checksTypes.CLOSE_CHECKS_DETAIL,
    }
}

const addInStorgeList = (id) => ({type: checksTypes.ADD_IN_CHECKS_STORGE_LIST, id});
const deleteFromStorgeList = (id) => ({type: checksTypes.DELETE_FROM_CHECKS_STORGE_LIST, id});


export const checksActions = {
    showDetail: showDetailForm,
    search: sendSearchRequest,
    closeDetail: closeDetail,
    addInStorgeList,
    deleteFromStorgeList,
}

