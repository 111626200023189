import axios from "axios";
import {deleteAxiosAPI, getAxiosAPI, postAxiosAPI} from "../../../helpers/axios_helpers";
import qs from 'qs';
import { getCookie } from 'react-use-cookie';


export const transportAPI = {
    search: postSearchRequest,
    getDetail: getTransportDetailRequest,
    saveRequest,
    addInStorgeList,
    deleteFromStorgeList,
}

async function postSearchRequest(searchParams) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'transport/search/',
        data: qs.stringify(searchParams),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function saveRequest(id) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'transport/save_request_made/',
        data: qs.stringify({
            'request_object_id': id
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function getTransportDetailRequest(id){
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'transport/'+ id + '/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function addInStorgeList(entityID) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'transport/tracked_list/',
        data: qs.stringify({
            'tracked_object': entityID
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function deleteFromStorgeList(entityID) {
    const resp = await axios({
        ...deleteAxiosAPI,
        url: deleteAxiosAPI.url + `transport/tracked_list/${entityID}/`,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}