import axios from "axios";
import {deleteAxiosAPI, getAxiosAPI, postAxiosAPI} from "../../../helpers/axios_helpers";
import qs from "qs";
import { getCookie } from 'react-use-cookie';


export const rfpiAPI = {
    search: postSearchRequest,
    // getDetail: getDetail,
    getRequests: getRequests,
    requestMade,
    addInStorgeList,
    deleteFromStorgeList

}

async function postSearchRequest(searchParams, page) {
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'rfpi/checked/?' + searchParams + '&offset=10&page=' + page,
        // data: qs.stringify(searchParams)
    });
    return resp
}

// async function getDetail(id) {
//     const resp = await axios({
//         ...getAxiosAPI,
//         url: getAxiosAPI.url + 'rfpi/checked/?' + searchParams + '&offset=10&page=' + page,
//         // data: qs.stringify(searchParams)
//     });
//     return resp
// }

async function requestMade(id) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'dolphin_rfpi/request_made/',
        data: qs.stringify({'checked_object': id})
    });
    return resp
}


async function getRequests(id) {
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'dolphin_rfpi/info_request/?checked_id='+ id,
    });
    return resp
}

async function addInStorgeList(id) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'dolphin_rfpi/security_list/',
        data: qs.stringify({
            'id': id
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function deleteFromStorgeList(id) {
    const resp = await axios({
        ...deleteAxiosAPI,
        url: deleteAxiosAPI.url + `dolphin_rfpi/security_list/${id}/`,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}