import React from "react";
import "./RealtyDetails.module.scss";
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import {isInStorgeList} from "../../helpers/storgelist_handlers";



function convertOutputDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}


/**
 * @typedef SbsDetailsPropsType
 * @property {JSX.Element | string} children
 */

/**
 *
 * @param {SbsDetailsPropsType} props
 * @returns {JSX.Element}
 */

export const RealtyDetails = (props) => {
    const ob = props.realtyObjectData
    // const inStorageList = isInStorgeList(ob.id, props.storgeList)
  return (
    <div className="BoxesList">
      <ResultBox
        headerItems={
          <React.Fragment>
          {/*<Button additionalCssClass="_addToList"*/}
          {/*        onClick={!inStorageList*/}
          {/*            ? ()=> props.addInStorgeList(ob.id)*/}
          {/*            : () => props.deleteFromStorgeList(ob.id)}>*/}
          {/*  {!inStorageList ? 'Добавить в сторожевой лист' : 'Удалить из сторжевого листа'}*/}
          {/*</Button>*/}
            <Button className="Close-button" onClick={props.onClose}>
                <span aria-hidden="true">&times;</span>
            </Button>
          </React.Fragment>
        }
      >
          <FieldValueText
            field="Кадастровый номер объекта:"
            value={ob.kadastr_number}
          />
          <FieldValueText field="Адрес объекта:" value={ob.full_address} />
          {ob.ownerships && ob.ownerships.map((ows, idx) =>
            <React.Fragment key={`item-${idx}`}>
                <hr/>
                <FieldValueText  field="Доля владения: " value={ows.part_ownership} />
                <FieldValueText  field="Дата регистрации владения: " value={convertOutputDate(ows.date_registration)} />
                <FieldValueText  field="Дата прекращения владения: " value={convertOutputDate(ows.date_cancel_registration)} />
                <FieldValueText  field="ФИО правообладателя: " value={ows.owner.full_name} />
                <FieldValueText  field="ИНН: " value={ows.owner.inn} />
                <FieldValueText  field="Номер и серия паспорта: " value={ows.owner.ul_number} />
                <FieldValueText  field="Дата выдачи паспорта: " value={convertOutputDate(ows.owner.ul_date)} />
                <FieldValueText  field="Дата рождения: " value={convertOutputDate(ows.owner.birth_date)} />
                <FieldValueText  field="Дата смерти: " value={convertOutputDate(ows.owner.death_date)} />
                <FieldValueText  field="Адрес места жительства:" value={ows.owner.address} />

            </React.Fragment>

          )}
          
      </ResultBox>
     
    </div>
  );
};
