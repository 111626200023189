import React from "react";
import "./SbsDetails.module.css";
import {ResultBox} from "../../ui/ResultBox/ResultBox";
import {FieldValueText} from "../../ui/Typography/Typography";
import {Button} from "../../ui/Button/Button";
import {sbsActions} from "./services/sbs_actions";
import {connect} from "react-redux";
import {Amounts} from "./amounts";
import {Requests} from "./requests";
import {Comments} from "./comments";
import {isInStorgeList} from "../../helpers/storgelist_handlers";

/**
 * @typedef SbsDetailsPropsType
 * @property {JSX.Element | string} children
 */

/**
 *
 * @param {SbsDetailsPropsType} props
 * @returns {JSX.Element}
 */



// TODO onClick
// TODO Выгрузка в PDF
class SbsDetails extends React.Component {


    render() {
        const ob = this.props.sbsObjectData
        console.log('ob: ', ob)
        const inStorageList = isInStorgeList(ob.id, this.props.storgeList)
        console.log('inStorageList: ', inStorageList)
        return (
            <div className="BoxesList">
                <ResultBox
                    headerItems={
                        <React.Fragment>
                            <Button additionalCssClass="_addToList"
                                    onClick={!inStorageList
                                        ? ()=> this.props.addInStorgeList(ob.id)
                                        : () => this.props.deleteFromStorgeList(ob.id)}>
                                {!inStorageList ? 'Добавить в сторожевой лист' : 'Удалить из сторжевого листа'}
                            </Button>
                            <Button className="Close-button" onClick={this.props.closeDetail}>
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </React.Fragment>
                    }
                >
                    <FieldValueText
                        field="Наименование организации:"
                        value={`${ob.entity_type} ${ob.entity_name}`}
                    />
                    <FieldValueText field="Статус:" value={ob.entity_status}/>
                    <FieldValueText field={ob.entity_type === 'ИП' ? 'ОГРНИП: ' : 'ОГРН: '} value={ob.ogrn_ogrnip}/>
                    <FieldValueText field="ИНН: " value={ob.inn}/>
                    <FieldValueText field="КПП: " value={ob.kpp}/>
                    <FieldValueText field="Адрес: " value={ob.address}/>
                    {ob.entity_type === 'ИП' &&
                        <FieldValueText field="Фамилия: " value={ob.last_name}/>}
                    {ob.entity_type === 'ИП' &&
                        <FieldValueText field="Имя: " value={ob.first_name}/>}
                    {ob.entity_type === 'ИП' &&
                        <FieldValueText field="Отчество: " value={ob.middle_name}/>
                    }

                </ResultBox>
                <ResultBox
                    expandTitle="Показать счета"
                    collapseTitle="Скрыть счета"
                    onClick={() => this.props.showAmounts(ob.id)}
                    notExpanded={true}
                >
                    {this.props.waitAmounts ? <h3>Загрузка счетов....</h3> :
                        <Amounts
                            amounts={this.props.amounts}
                        />}
                </ResultBox>

                <ResultBox
                    expandTitle="Показать запросы"
                    collapseTitle="Скрыть запросы"
                    onClick={() => (this.props.showRequests(ob.id))}
                    notExpanded={true}
                >
                    {this.props.waitRequests ? <h3>Загрузка запросов....</h3> :
                        <Requests
                            requests={this.props.requests}
                        />}
                </ResultBox>
                <ResultBox
                    expandTitle="Показать комментарии"
                    collapseTitle="Скрыть комментарии"
                    onClick={() => (this.props.showComments(ob.id))}
                    notExpanded={true}
                >
                    {this.props.waitComments ? <h3>Загрузка комментариев....</h3> :
                        <Comments
                            comments={this.props.comments}
                            addComment={this.props.addComment}
                            entityID={ob.id}
                        />}
                </ResultBox>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.sbs.showDetail,
        sbsObjectData: state.sbs.sbsObjectData,
        sendRequest: state.sbs.sendRequest,
        waitAmounts: state.sbs.waitAmounts,
        waitRequests: state.sbs.waitRequests,
        waitComments: state.sbs.waitComments,
        amounts: state.sbs.amounts,
        requests: state.sbs.requests,
        comments: state.sbs.comments,

        loading: state.sbs.loading,
        storgeList: state.sbs.storgeList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(sbsActions.closeDetail()),
        showComments: (id) => dispatch(sbsActions.showComments(id)),
        showRequests: (id) => dispatch(sbsActions.showRequests(id)),
        showAmounts: (id) => dispatch(sbsActions.showAmounts(id)),
        addComment: (comment, id) => dispatch(sbsActions.addComment(comment, id)),
        addInStorgeList: (id) => dispatch(sbsActions.addInStorgeList(id)),
        deleteFromStorgeList: (id) => dispatch(sbsActions.deleteFromStorgeList(id)),
        //TODO getPDF()
        //TODO
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SbsDetails);