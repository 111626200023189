import React from 'react';
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText} from "../../ui/Typography/Typography";

function convertDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}

const SearchItem = props => {
    return (
        <ResultBox onClick={()=>props.onClick(props.object.id)}>
                <FieldValueText field="Кадастровый номер объекта:" value={props.object.kadastr_number}/>
                <FieldValueText field="Адрес: " value={props.object.full_address}/>
                <FieldValueText field="ФИО собственника: " value={props.object.owner.full_name} />
                <FieldValueText field="Дата рождения: " value={convertDate(props.object.owner.birth_date)} />
        </ResultBox>

       
    );
}



export default SearchItem;
