import {call, put, takeEvery} from "redux-saga/effects";
import {
    checksTypes
} from "./action_types";
import {isDebug} from "../../../../constants/config";
import {checksAPI} from "./api";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";


function* searchSaga(action) {
    yield put({
        type: checksTypes.SEARCH_CHECKS_START,
    })
    try {
        const resp = yield call(checksAPI.search, action.searchParams);

        yield put({
            type: checksTypes.SEARCH_CHECKS_SUCCESS,
            foundObjects: resp.data,
        });
    } catch(error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + message
        });
        yield put({
            type: checksTypes.SEARCH_CHECKS_ERROR,
            error: error
        });
    }
}

function* getTransportDetail(action) {
    yield put({
        type: checksTypes.SHOW_CHECKS_DETAIL_START,
    })
    try {
        const resp = yield call(checksAPI.getDetail, action.id)

        yield put({
            type: checksTypes.SHOW_CHECKS_DETAIL_SUCCESS,
            objectData: resp.data
        })
    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения данных объекта. ' + message
        });
        yield put({
            type: checksTypes.SHOW_CHECKS_DETAIL_ERROR,
            error: error
        });

    }
}

function* saveRequest(action) {
    yield put({
        type: checksTypes.SAVE_CHECKS_REQUEST_START,
    })
    try{
        const resp = yield call(checksAPI.saveRequest, action.id)
        if (isDebug) {
            console.log(resp.statusText)
        }
        yield put({
            type: checksTypes.SAVE_CHECKS_REQUEST_SUCCESS,
        });
    }catch(error){
        yield put({
            type: checksTypes.SAVE_CHECKS_REQUEST_ERROR,
            error: error
        });
    }
}

function* addInStorgeListsSaga(action) {

    try{
        yield call(checksAPI.addInStorgeList, action.id)
        yield put({
            type: checksTypes.ADD_IN_CHECKS_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){

        yield put({
            type: checksTypes.ADD_IN_CHECKS_STORGE_LIST_ERROR,
            error: error
        });
    }
}

function* deleteFromStorgeListsSaga(action) {

    try{
        yield call(checksAPI.deleteFromStorgeList, action.id)
        yield put({
            type: checksTypes.DELETE_FROM_CHECKS_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        yield put({
            type: checksTypes.DELETE_FROM_CHECKS_STORGE_LIST_ERROR,
            error: error
        });
    }
}


export function* checksSagas() {
    yield takeEvery(checksTypes.CHECKS_SEARCH, searchSaga);
    yield takeEvery(checksTypes.SEND_CHECKS_REQUEST_DETAIL, getTransportDetail);
    yield takeEvery(checksTypes.SHOW_CHECKS_DETAIL_SUCCESS, saveRequest);
    yield takeEvery(checksTypes.ADD_IN_CHECKS_STORGE_LIST, addInStorgeListsSaga);
    yield takeEvery(checksTypes.DELETE_FROM_CHECKS_STORGE_LIST, deleteFromStorgeListsSaga);
}