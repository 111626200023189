import {cabTypes} from "./cabinet_action_types";



export const openCabinet = () => ({type: cabTypes.OPEN_CABINET,});
export const approveUserInfo = (delay) => ({type: cabTypes.APPROVE_USER_INFO, delay});
export const openReference = () => ({type: cabTypes.OPEN_USER_REFERENCE,});
export const findUsers = (searchParams) => ({type: cabTypes.FIND_USER_INFO, searchParams});
export const openSupport = () => ({type: cabTypes.OPEN_SUPPORT,});
export const openStorgeList = () => ({type: cabTypes.OPEN_STORGE_LIST,});

export const openSBS = () => ({type: cabTypes.OPEN_SBS});
export const openRFPI = () => ({type: cabTypes.OPEN_RFPI,});
export const openAmber = () => ({type: cabTypes.OPEN_AMBER,});
export const openCars = () => ({type: cabTypes.OPEN_CARS,});
export const openRealty = () => ({type: cabTypes.OPEN_REALTY,});
export const openTransport = () => ({type: cabTypes.OPEN_TRANSPORT,});
export const openChecks = () => ({type: cabTypes.OPEN_CHECKS,});
export const openBotClient = () => ({type: cabTypes.OPEN_BOT_CLIENT,});
export function sendToSupport(subject, message) {
    return {
        type: cabTypes.SEND_SUPPORT_MESSAGE,
        subject,
        message,
    }
}

export const sbsClick = (id) => ({type: cabTypes.STORGE_SBS_CLICK, id});
export const rfpiClick = (id) => ({type: cabTypes.STORGE_RFPI_CLICK, id});
export const transportClick = (id) => ({type: cabTypes.STORGE_TRANSPORT_CLICK, id});

export const closePopupMessage = () => ({type: cabTypes.CLOSE_POPUP_WINDOW})
export const closePopupError = () => ({type: cabTypes.CLOSE_POPUP_ERROR})
export const logout = () => ({type: cabTypes.LOGOUT})


export const cabinetActions = {
    openCabinet,
    approveUserInfo,
    openReference,
    findUsers,
    openSupport,
    openStorgeList,
    openSBS,
    openRFPI,
    openAmber,
    openCars,
    openRealty,
    openTransport,
    openChecks,
    openBotClient,
    sendToSupport,
    sbsClick,
    rfpiClick,
    transportClick,
    logout,
}
