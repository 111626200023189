import {
    ADD_IN_SBS_STORGE_LIST,
    ADD_SBS_COMMENTS,
    CLOSE_SBS_DETAIL, DELETE_FROM_SBS_STORGE_LIST,
    SBS_SEND_SEARCH_REQUEST, SHOW_SBS_AMOUNTS, SHOW_SBS_COMMENTS, SHOW_SBS_DETAIL, SHOW_SBS_REQUESTS,
} from "./sbs_action_types";


const showSBSDetailForm = (id) => ({type: SHOW_SBS_DETAIL, id});
const sendSearchRequest = (searchParams) => ({type: SBS_SEND_SEARCH_REQUEST, searchParams});
const closeSBSDetail = () => ({type: CLOSE_SBS_DETAIL});
const showAmounts = (id) => ({type: SHOW_SBS_AMOUNTS, id});
const showRequests = (id) => ({type: SHOW_SBS_REQUESTS, id});
const showComments = (id) => ({type: SHOW_SBS_COMMENTS, id});
const addComment = (comment, id) => ({type: ADD_SBS_COMMENTS, comment, id});

const addInStorgeList = (id) => ({type: ADD_IN_SBS_STORGE_LIST, id});
const deleteFromStorgeList = (id) => {
    console.log('id: ', id)
    return {type: DELETE_FROM_SBS_STORGE_LIST, id}
}

export const sbsActions = {
    showDetailForm: showSBSDetailForm,
    search: sendSearchRequest,
    closeDetail: closeSBSDetail,
    showAmounts: showAmounts,
    showRequests: showRequests,
    showComments: showComments,
    addComment: addComment,
    addInStorgeList,
    deleteFromStorgeList,
}

