import React from 'react';
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText} from "../../ui/Typography/Typography";

function convertDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}

const SearchItem = props => {
    return (
        <ResultBox onClick={()=>props.onClick(props.object.id)}>
            <FieldValueText field="ФИО: " value={props.object.last_name + ' ' + props.object.first_name + ' ' + props.object.middle_name}/>
            <FieldValueText field="Дата рождения: " value={convertDate(props.object.birth_date)}/>
            <FieldValueText field="Серия\№ паспорта: " value={props.object.passport_series + ' ' + props.object.passport_number} />
            <FieldValueText field="Кем выдан: " value={props.object.passport_received} />
        </ResultBox>


    );
}



export default SearchItem;
