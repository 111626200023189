import React from 'react';
import {connect} from "react-redux";

import {rfpiActions} from "./service/action";
import {TestFunc} from "../../helpers/test_component";
import RFPISearch from "./search";
// import {isInStorgeList} from "../../helpers/helpers"


class RFPIComponent extends React.Component {
    renderSearchTab = () => {
        if (this.props.showDetail) {
            //TODO передать статус объекта
            // return <TransportDetails
            //     onClose={this.props.closeDetail}
            //     objectData={this.props.objectData}
            //     inStorageList={false}
            // />;
            return <TestFunc text={'Подробно'}/>
        } else {
            return <RFPISearch />;
        }
    }

    updateResult = (x, y, z) => {
        this.setState({
            resultData: x,
            searchQuery: y,
            searchUrl: z,
        });
        if (x.length > 10) {
            this.setState({maxFoundPage: x.length / 10, foundPage: 1})
        } else {
            this.setState({maxFoundPage: x.length / 10, foundPage: 1})
        }

    };

    render() {
        return (this.renderSearchTab())
    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.rfpi.showDetail,
        objectData: state.rfpi.objectData,
        foundObjects: state.rfpi.foundObjects,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(rfpiActions.closeDetail())
        //TODO getPDF()
        //TODO
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RFPIComponent);