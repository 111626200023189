import PrivateCabinet from "../../../../cabinets/userCabinet/PrivateCabinet"
import {openCabinet, openReference, 
    openSupport, openStorgeList} from "../cabinet_actions"
import Support from '../../../../cabinets/Support'
import UserReference from "../../../../cabinets/UserReference";
import StorageList from "../../../../cabinets/StorgeList/StorageList";


export const SEARCH = 'SEARCH';
export const CABINET = 'CABINET';
export const SUPPORT = 'SUPPORT';
export const USER_REFERENCE = 'USER_REFERENCE';
export const STORGE_LIST = 'STORGE_LIST';

export const sidebarMenuItems = {
    // [SEARCH]: {
    //     icon: "search",
    //     text: "Поиск",
    //     route: "search",
    //     onClick: openCabinet,
    //     show: true,
    //     accessField: null,
    //     component: PrivateCabinet,
    // },
    [CABINET]: {
        icon: "person",
        route: "cabinet",
        text: "Личный кабинет",
        onClick: openCabinet,
        show: true,
        accessField: null,
        component: PrivateCabinet,
    },
    [SUPPORT]: {
        icon: "talk",
        route: "support",
        text: "Написать в техподдержку",
        onClick: openSupport,
        show: true,
        component: Support,
    },
    [USER_REFERENCE]: {
        icon: "book",
        route: "user_reference",
        text: "Справочник пользователей",
        onClick: openReference,
        show: false,
        accessField: "user_reference_access",
        component: UserReference,
    },
    [STORGE_LIST]:{
        icon: "shield",
        route: "storge_list",
        text: "Сторожевой лист",
        onClick: openStorgeList,
        show: true,
        component: StorageList,
    },
}