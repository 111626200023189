import React from "react";
import {FieldValueText} from "../../ui/Typography/Typography";
import {Icon} from "../../ui/Icons/Icon";
import "./StorgeList.module.css";


export const RFPIItem = (props) => {


    return (
        <div className="WatchListItem">
            <div className="WatchListItemInner">
                <div className="WatchListItemBody" onClick={()=>props.onClick(props.item.object_id)}>
                    <FieldValueText field="ФИО: " value={props.item.name}/>
                    <FieldValueText field="ИНН: " value={props.item.phone_number}/>
                    <FieldValueText field="Адрес: " value={props.item.address}/>
                    <FieldValueText
                        field="Дата внесения: "
                        value={props.item.date}
                    />
                    <FieldValueText field="Зафиксировано запросов: " value={props.item.viewed_counter} />
                </div>
                <div className="WatchListItemFooter">
              <span className="StyledLink _blue" onClick={() => (props.onDelete(props.item.object_id))}>
                <Icon style={{ marginRight: 8 }} type="trash" />
                Убрать из листа
              </span>
                    <span className="WatchListItemBell">
                <Icon type="bell" modifier="_red" />
                <span className="WatchListItemBellCount">{props.item.viewed_counter}</span>
              </span>
                </div>
            </div>
            <div className="WatchListItemNotification">
                <div className="WatchListItemNotificationMessage">
                    Изменился адрес жительства
                </div>
                <Icon type="close" />
            </div>
        </div>
    )
}