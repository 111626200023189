import {Types} from "./action_types";


function showDetailForm(id) {
    return {
        type: Types.SEND_AUTOZALOG_REQUEST_DETAIL,
        id
    }
}

function sendSearchRequest(searchParams){

    return {
        type: Types.AUTOZALOG_SEARCH,
        searchParams,
    }
}
function closeDetail(){
    return {
        type: Types.CLOSE_AUTOZALOG_DETAIL,
    }
}

const addInStorgeList = (id) => ({type: Types.ADD_IN_AUTOZALOG_STORGE_LIST, id});
const deleteFromStorgeList = (id) => ({type: Types.DELETE_FROM_AUTOZALOG_STORGE_LIST, id});

const showRequests = (id) => ({type: Types.SHOW_AUTOZALOG_REQUESTS, id});
const showComments = (id) => ({type: Types.SHOW_AUTOZALOG_COMMENTS, id});
const addComment = (comment, id) => ({type: Types.ADD_AUTOZALOG_COMMENTS, comment, id});


export const autozalogActions = {
    showDetail: showDetailForm,
    search: sendSearchRequest,
    closeDetail: closeDetail,
    addInStorgeList,
    deleteFromStorgeList,
    showRequests: showRequests,
    showComments: showComments,
    addComment,
}

