import {
   Types
} from "./transport_action_types";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";


const initialState = {
    showDetail: false,
    objectData: null,
    foundObjects: null,
    sendRequest: false,
    loading: false,
    error: null,
    searchParams: null,
    storgeList: null,
    updateSearch: false,
}


const handlers = {
    [Types.SAVE_TRANSPORT_REQUEST_START]: (state, action) =>({...state, loading: false}),
    [Types.SAVE_TRANSPORT_REQUEST_SUCCESS]: (state, action)=>({...state, loading: false}),
    [Types.SEND_TRANSPORT_REQUEST]: (state, action) =>({...state, loading: true, foundObjects: null}),

    [Types.SHOW_TRANSPORT_DETAIL_START]: (state, action)=>({...state, showError:false, error: action.error}),
    [Types.SHOW_TRANSPORT_DETAIL_SUCCESS]: (state, action) =>({...state, loading: false, showDetail: true, objectData: action.objectData}),
    [Types.SHOW_TRANSPORT_DETAIL_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [Types.CLOSE_TRANSPORT_DETAIL]: (state, action)=>({...state, showDetail: false}),

    [Types.SEARCH_TRANSPORT_START]: (state, action) =>({...state, loading: true, error: null}),
    [Types.SEARCH_TRANSPORT_SUCCESS]: (state, action)=>({...state, loading: false, foundObjects: action.foundObjects}),
    [Types.SEARCH_TRANSPORT_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),

    [cabTypes.GET_STORGE_LIST_SUCCESS]: (state, action)=>({...state, storgeList: action.transportIDS}),


    DEFAULT: state => state
}

export function transportReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}