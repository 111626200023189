import React from "react";
import './PopupError.css'

const PopupError = props => {
    return (
        <div className="error-popup-box">
            <div className="error-box">
                <span className="error-close-icon" onClick={props.handleClose}>x</span>
                {props.content}
                {props.children}
            </div>
        </div>
    );
};

export default PopupError;