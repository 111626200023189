import React from 'react';
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText} from "../../ui/Typography/Typography";

function convertDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}

const SearchItem = props => {
    return (
        <ResultBox onClick={()=>props.onClick(props.object.id)}>
            <FieldValueText field="Гос. рег. знак: " value={props.object.gos_reg_znak}/>
            <FieldValueText field="Марка: " value={props.object.model_ts}/>
            <FieldValueText field="ФИО собственника: " value={props.object.owner.full_name} />
            <FieldValueText field="Дата рождения: " value={convertDate(props.object.owner.birth_date)} />
        </ResultBox>


    );
}



export default SearchItem;
