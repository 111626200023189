import React from 'react';
import {connect} from "react-redux";
import SbsDetails from "../SBS/sbs_detail";
import StorageListCommon from "./storgeListCommon";


function StorageList(props) {


    if (props.showDetail) {
        return (<SbsDetails
            // inStorageList={this.state.inStorageList}
            // changeTrackedState={this.changeTrackedState}
        />);
    }else {

        return <StorageListCommon />
    }

}

function getDetailComponent(moduleName) {
    switch (moduleName) {
        case 'sbs':
            return

    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.cabinet.showDetail,
        loading: state.cabinet.loading,
        storgeList: state.cabinet.storgeList
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorageList);