export const checksTypes = {
    SEND_CHECKS_REQUEST: 'SEND_CHECKS_REQUEST',
    SEND_CHECKS_REQUEST_DETAIL: 'SEND_CHECKS_REQUEST_DETAIL',
    CHECKS_SEARCH: 'CHECKS_SEARCH',
    SAVE_CHECKS_REQUEST_START: 'SAVE_CHECKS_REQUEST_START',
    SAVE_CHECKS_REQUEST_SUCCESS: 'SAVE_CHECKS_REQUEST_SUCCESS',
    SAVE_CHECKS_REQUEST_ERROR: 'SAVE_CHECKS_REQUEST_ERROR',
    CLOSE_CHECKS_DETAIL: 'CLOSE_CHECKS_DETAIL',
    SHOW_CHECKS_DETAIL_START: 'SHOW_CHECKS_DETAIL_START',
    SHOW_CHECKS_DETAIL_SUCCESS: 'SHOW_CHECKS_DETAIL_SUCCESS',
    SHOW_CHECKS_DETAIL_ERROR: 'SHOW_CHECKS_DETAIL_ERROR',
    SEARCH_CHECKS_START: 'SEARCH_CHECKS_START',
    SEARCH_CHECKS_SUCCESS: 'SEARCH_CHECKS_SUCCESS',
    SEARCH_CHECKS_ERROR: 'SEARCH_CHECKS_ERROR',

    ADD_IN_CHECKS_STORGE_LIST: 'ADD_IN_CHECKS_STORGE_LIST',
    ADD_IN_CHECKS_STORGE_LIST_SUCCESS: 'ADD_IN_CHECKS_STORGE_LIST_SUCCESS',
    ADD_IN_CHECKS_STORGE_LIST_ERROR: 'ADD_IN_CHECKS_STORGE_LIST_ERROR',
    DELETE_FROM_CHECKS_STORGE_LIST: 'DELETE_FROM_CHECKS_STORGE_LIST',
    DELETE_FROM_CHECKS_STORGE_LIST_SUCCESS: 'DELETE_FROM_CHECKS_STORGE_LIST_SUCCESS',
    DELETE_FROM_CHECKS_STORGE_LIST_ERROR: 'DELETE_FROM_CHECKS_STORGE_LIST_ERROR',
}