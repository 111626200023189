export const Types = {
    SEND_AUTOZALOG_REQUEST: 'SEND_AUTOZALOG_REQUEST',
    SEND_AUTOZALOG_REQUEST_DETAIL: 'SEND_AUTOZALOG_REQUEST_DETAIL',
    AUTOZALOG_SEARCH: 'AUTOZALOG_SEARCH',
    SAVE_AUTOZALOG_REQUEST_START: 'SAVE_AUTOZALOG_REQUEST_START',
    SAVE_AUTOZALOG_REQUEST_SUCCESS: 'SAVE_AUTOZALOG_REQUEST_SUCCESS',
    SAVE_AUTOZALOG_REQUEST_ERROR: 'SAVE_AUTOZALOG_REQUEST_ERROR',
    CLOSE_AUTOZALOG_DETAIL: 'CLOSE_AUTOZALOG_DETAIL',
    SHOW_AUTOZALOG_DETAIL_START: 'SHOW_AUTOZALOG_DETAIL_START',
    SHOW_AUTOZALOG_DETAIL_SUCCESS: 'SHOW_AUTOZALOG_DETAIL_SUCCESS',
    SHOW_AUTOZALOG_DETAIL_ERROR: 'SHOW_AUTOZALOG_DETAIL_ERROR',
    SEARCH_AUTOZALOG_START: 'SEARCH_AUTOZALOG_START',
    SEARCH_AUTOZALOG_SUCCESS: 'SEARCH_AUTOZALOG_SUCCESS',
    SEARCH_AUTOZALOG_ERROR: 'SEARCH_AUTOZALOG_ERROR',

    ADD_IN_AUTOZALOG_STORGE_LIST: 'ADD_IN_AUTOZALOG_STORGE_LIST',
    ADD_IN_AUTOZALOG_STORGE_LIST_SUCCESS: 'ADD_IN_AUTOZALOG_STORGE_LIST_SUCCESS',
    ADD_IN_AUTOZALOG_STORGE_LIST_ERROR: 'ADD_IN_AUTOZALOG_STORGE_LIST_ERROR',
    DELETE_FROM_AUTOZALOG_STORGE_LIST: 'DELETE_FROM_AUTOZALOG_STORGE_LIST',
    DELETE_FROM_AUTOZALOG_STORGE_LIST_SUCCESS: 'DELETE_FROM_AUTOZALOG_STORGE_LIST_SUCCESS',
    DELETE_FROM_AUTOZALOG_STORGE_LIST_ERROR: 'DELETE_FROM_AUTOZALOG_STORGE_LIST_ERROR',

    SHOW_AUTOZALOG_REQUESTS: 'SHOW_AUTOZALOG_REQUESTS',
    SHOW_AUTOZALOG_REQUESTS_START: 'SHOW_AUTOZALOG_REQUESTS_START',
    SHOW_AUTOZALOG_REQUESTS_SUCCESS: 'SHOW_AUTOZALOG_REQUESTS_SUCCESS',
    SHOW_AUTOZALOG_REQUESTS_ERROR: 'SHOW_AUTOZALOG_REQUESTS_ERROR',
    SHOW_AUTOZALOG_COMMENTS: 'SHOW_AUTOZALOG_COMMENTS',
    ADD_AUTOZALOG_COMMENTS: 'ADD_AUTOZALOG_COMMENTS',
    ADD_AUTOZALOG_COMMENT_SUCCESS: 'ADD_AUTOZALOG_COMMENT_SUCCESS',
    ADD_AUTOZALOG_COMMENT_ERROR: 'ADD_AUTOZALOG_COMMENT_ERROR',
    SHOW_AUTOZALOG_COMMENTS_START: 'SHOW_AUTOZALOG_COMMENTS_START',
    SHOW_AUTOZALOG_COMMENTS_SUCCESS: 'SHOW_AUTOZALOG_COMMENTS_SUCCESS',
    SHOW_AUTOZALOG_COMMENTS_ERROR: 'SHOW_AUTOZALOG_COMMENTS_ERROR',
}