import axios from "axios";
import {postAxiosAPI, getAxiosAPI, deleteAxiosAPI} from "../../../helpers/axios_helpers";
import { getCookie } from 'react-use-cookie';


export const botApi = {
    getClient,
    closeClient,
}

async function getClient() {
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'dolphin_bot/session/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function closeClient(uuid) {
    const resp = await axios({
        ...deleteAxiosAPI,
        url: deleteAxiosAPI.url + `dolphin_bot/session/?session_uuid=${uuid}`,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}