import React from "react";
import Auth from "./auth_page";
import LayoutPage from "./layout_page";
import Test from "../helpers/test_component";
import ContentPage from "./content_page";
import {connect} from "react-redux";
import {PAGES} from "./services/index/data";


function App(props) {
    switch (props.activePage) {
        case PAGES.auth:
            return <Auth/>
        case PAGES.userCabinet:
            return (
                <LayoutPage>
                    <ContentPage />
                </LayoutPage>
            );
        case PAGES.managerCabinet:
            console.log('Manager page')
            return <Test/>;
        default:
            console.log('Unknown page')
    }
}

function mapStateToProps(state) {
    return {
        activePage: state.index.activePage,
        userInfo: state.index.userInfo,
        showError: state.index.showError,
        showPopup: state.index.showPopup,
        error: state.index.error,
    }
}

// function mapDispatchToProps(dispatch) {
//     return {
//
//     }
// }


export default connect(mapStateToProps, null)(App);