import React, {useCallback, useState} from "react";
import Select from "react-select";
import {FieldValueText} from "../../ui/Typography/Typography";
import {formatDate} from "../../helpers/handleDate";


const filterAmountsByType = (type, amounts) => {
    const filteredAmounts = amounts.filter(function (item, index, array) {
        return item.amount_type === type;
    });
    if (type === 'Все') {
        return amounts
    } else {
        return filteredAmounts
    }
}

const options = [
    {value: "all", label: "Все"},
    {value: "deposit", label: "Депозитный"},
    {value: "raschet", label: "Расчетный"},
    {value: "special", label: "Специальный"},
];

//TODO Починить фильтр
export function Amounts(props) {
    const [selectedFilter, setSelectedFilter] = useState(options[0]);
    const [filteredAmounts, filterAmounts] = useState(props.amounts.results || null);

    const changeFilter = useCallback((e) => {
        e.preventDefault();
        filterAmounts(filterAmountsByType(e.label, props.amounts.results));
    }, [selectedFilter, setSelectedFilter]);


    return (
        <React.Fragment>
            <form className="Form" onSubmit={null}>
                <fieldset className="FormFieldSet">
                    <Select
                        defaultValue={selectedFilter}
                        onChange={changeFilter}
                        options={options}
                    />
                </fieldset>
            </form>
            {props.amounts.results && filteredAmounts.map((item, idx) => (
                <React.Fragment>
                    <FieldValueText field="Номер счета: " value={item.amount}/>
                    <FieldValueText field="Банк: " value={item.bank_name}/>
                    <FieldValueText field="БИК: " value={item.bank_bik}/>
                    <FieldValueText field="Статус: " value={item.amount_status}/>
                    <FieldValueText field="Тип счета: " value={item.amount_type}/>
                    <FieldValueText field="Валюта: " value={item.currency}/>
                    <FieldValueText field="Дата открытия: " value={formatDate(item.open_date)}/>
                    <FieldValueText field="Дата изменения: " value={formatDate(item.edit_date)}/>
                    <FieldValueText field="Дата закрытия: " value={formatDate(item.close_date)}/>
                    <hr/>
                </React.Fragment>
            ))}
        </React.Fragment>
    )
}