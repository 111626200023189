import React from 'react';
import {connect} from "react-redux";
import SBSSearchForm from "./sbs_search";
import SbsDetails from "./sbs_detail";


class SBSComponent extends React.Component {

    state = {
        showEntityForm: false,
        inStorageList: false
    }

    renderSearchTab = () => {
        if (this.props.showDetail) {
           return (<SbsDetails
              inStorageList={this.state.inStorageList}
              // changeTrackedState={this.changeTrackedState}
          />);
        }else {

          return (<SBSSearchForm
                foundPage={this.state.foundPage}
                maxFoundPage={this.state.maxFoundPage}
            />);
        }
      }


    render(){
        return(this.renderSearchTab())
    }

}

function mapStateToProps(state) {
    return {
        showDetail: state.sbs.showDetail,
        loading: state.sbs.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //TODO getPDF()
        //TODO 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SBSComponent);