import React from "react";
import "./Menu.module.css";
import { Icon } from "../Icons/Icon";
import {NavLink} from "react-router-dom";
import {openCabinet} from "../../pages/services/cabinet/cabinet_actions";
import {connect} from "react-redux";

/**
 * @typedef MenuItem
 * @property {string} text
 * @property {string} icon
 * @property {string} route
 *
 */

/**
 * @typedef PropType
 * @property {Array<MenuItem>} items
 * @property {'horizontal' | 'vertical'} kind
 */

/**
 *
 * @param {PropType} props
 * @returns {JSX.Element}
 */

 const Menu = (props) => {
     const menu = props.items || props.menuItems


   //TODO Добавить вывод справочника пользователей по условию
  return (
    <ul className={`menu _${props.kind}`}>
      {Object.keys(menu).map((itemName, idx) => {
        const iconModifier = props.kind === "horizontal" ? "_red" : "";
        const item = menu[itemName]
        if (item.show) {
          return (

            <li key={`menu_${props.kind}_${idx}`} className="menuItem">
              <NavLink
                  to={''}
                className={({ isActive }) => (isActive ? "_selected" : "")}
                onClick={()=>props.onClick(item.onClick)}
              >
                <Icon type={item.icon} modifier={iconModifier} />
                <span className="menuItemText">{item.text}</span>
              </NavLink>
            </li>
          );} else {
            return null
        }

      })}
    </ul>
  );
};

function mapStateToProps(state) {
  return {
      userInfo: state.index.userInfo,
      menuItems: state.cabinet.sidebarMenuItems,
      error: state.cabinet.error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
      openCabinet: () => dispatch(openCabinet()),
      onClick: (fn) => dispatch(fn())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
