import {
    SEARCH_PAGE_LOAD,
    SET_ACTIVE_CABINET,
    SHOW_USER_PROFILE, SHOW_STORAGE_LIST, SHOW_SEND_MESSAGE_SUPPORT, SHOW_DB_UPDATE, SHOW_USER_REFERENCE, SHOW_SEARCH
} from "./user_profile_action_types";

export function loadSearchPage() {
    return {
        type: SEARCH_PAGE_LOAD,
    }
}

export function setActiveCabinet(cabinet) {
    return {
        type: SET_ACTIVE_CABINET,
        activeCabinet: cabinet.id,
        activeTab: 0,
        cabinet: { ...cabinet}
    }
}



export function selectTabSidebar(tab) {
    switch (tab) {
        case 0:
            return { type: SHOW_SEARCH, tab }
        case 1:
            return { type: SHOW_USER_PROFILE, }
        case 2:
            return { type: SHOW_STORAGE_LIST, tab }
        case 3:
            return { type: SHOW_SEND_MESSAGE_SUPPORT, tab }
        case 4:
            return { type: SHOW_DB_UPDATE, tab }
        case 5:
            return { type: SHOW_USER_REFERENCE, tab }
        default:
            return { type: SHOW_USER_PROFILE, tab: 1 }
    }
}
