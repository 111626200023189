export const Types = {
    SEND_TRANSPORT_REQUEST: 'SEND_TRANSPORT_REQUEST',
    SEND_TRANSPORT_REQUEST_DETAIL: 'SEND_TRANSPORT_REQUEST_DETAIL',
    TRANSPORT_SEARCH: 'TRANSPORT_SEARCH',
    SAVE_TRANSPORT_REQUEST_START: 'SAVE_TRANSPORT_REQUEST_START',
    SAVE_TRANSPORT_REQUEST_SUCCESS: 'SAVE_TRANSPORT_REQUEST_SUCCESS',
    SAVE_TRANSPORT_REQUEST_ERROR: 'SAVE_TRANSPORT_REQUEST_ERROR',
    CLOSE_TRANSPORT_DETAIL: 'CLOSE_TRANSPORT_DETAIL',
    SHOW_TRANSPORT_DETAIL_START: 'SHOW_TRANSPORT_DETAIL_START',
    SHOW_TRANSPORT_DETAIL_SUCCESS: 'SHOW_TRANSPORT_DETAIL_SUCCESS',
    SHOW_TRANSPORT_DETAIL_ERROR: 'SHOW_TRANSPORT_DETAIL_ERROR',
    SEARCH_TRANSPORT_START: 'SEARCH_TRANSPORT_START',
    SEARCH_TRANSPORT_SUCCESS: 'SEARCH_TRANSPORT_SUCCESS',
    SEARCH_TRANSPORT_ERROR: 'SEARCH_TRANSPORT_ERROR',

    ADD_IN_TRANSPORT_STORGE_LIST: 'ADD_IN_TRANSPORT_STORGE_LIST',
    ADD_IN_TRANSPORT_STORGE_LIST_SUCCESS: 'ADD_IN_TRANSPORT_STORGE_LIST_SUCCESS',
    ADD_IN_TRANSPORT_STORGE_LIST_ERROR: 'ADD_IN_TRANSPORT_STORGE_LIST_ERROR',
    DELETE_FROM_TRANSPORT_STORGE_LIST: 'DELETE_FROM_TRANSPORT_STORGE_LIST',
    DELETE_FROM_TRANSPORT_STORGE_LIST_SUCCESS: 'DELETE_FROM_TRANSPORT_STORGE_LIST_SUCCESS',
    DELETE_FROM_TRANSPORT_STORGE_LIST_ERROR: 'DELETE_FROM_TRANSPORT_STORGE_LIST_ERROR',
}
