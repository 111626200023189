import {Types} from "./action_types";


const initialState = {
    showMainMenu: false,
    showSubMenu: false,
    botSessionUUID: null,
    messageData: null,
    loading: false,
    error: null,
}

const handlers = {
    [Types.CLIENT_INITIALIZATION_ERROR]: (state, action) =>({...state, loading: false, error: action.error}),
    [Types.GET_CLIENT_SUCCESS]: (state, action) =>({...state, loading: false, showMainMenu: true, botSessionUUID: action.botSessionUUID}),
    [Types.CLIENT_IS_INITIALIZED]: (state, action) =>({...state, loading: false, showMainMenu: true}),
    [Types.SHOW_MAIN_MENU]: (state, action) =>({...state, showMainMenu: true, showSubMenu: false}),
    [Types.SHOW_SUB_MENU]: (state, action) =>({...state, showMainMenu: false, showSubMenu: true}),
    [Types.RECEIVE_MESSAGE]: (state, action) =>({...state, messageData: action.message, loading: false}),
    [Types.WAIT_MESSAGE]: (state, action) =>({...state, loading: true}),
    [Types.CLOSE_CLIENT]: (state, action) =>({...state, showMainMenu: false, showSubMenu: false, botSessionUUID: null}),

    DEFAULT: state => state
}

export function botClientReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}