import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {BrowserRouter, Route} from "react-router-dom";
import registerServiceWorker from './registerServiceWorker';
import App from "./components/pages/index_page";
import configureStore from './redux/store';
import rootReducer from "./redux/reducers";



const store = configureStore(rootReducer);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Route path="/">
                <App />
            </Route>
        </BrowserRouter>
    </Provider>);
registerServiceWorker();

