import {Types} from "./transport_action_types";


function showTransportDetailForm(id) {
    return {
        type: Types.SEND_TRANSPORT_REQUEST_DETAIL,
        id
    }
}

function sendSearchRequest(searchParams){

    return {
        type: Types.TRANSPORT_SEARCH,
        searchParams,
    }
}
function closeTransportDetail(){
    return {
        type: Types.CLOSE_TRANSPORT_DETAIL,
    }
}

const addInStorgeList = (id) => ({type: Types.ADD_IN_TRANSPORT_STORGE_LIST, id});
const deleteFromStorgeList = (id) => ({type: Types.DELETE_FROM_TRANSPORT_STORGE_LIST, id});


export const transportActions = {
    showDetail: showTransportDetailForm,
    search: sendSearchRequest,
    closeDetail: closeTransportDetail,
    addInStorgeList,
    deleteFromStorgeList,
}

