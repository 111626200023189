import {call, put, takeEvery} from "redux-saga/effects";
import { setCookie } from 'react-use-cookie';

import {
    ASK_CODE_ERROR, ASK_CODE_START, ASK_CODE_SUCCESS, CHECK_CODE_ERROR, CHECK_CODE_RETRY,
    CHECK_CODE_START, CHECK_CODE_SUCCESS,
    GET_TOKEN,
    GET_TOKEN_ERROR,
    GET_TOKEN_SUCCESS
} from "./auth_action_types";
import {authAPI} from "./auth_api";
import {cabTypes} from "./cabinet/cabinet_action_types";



function* getTokenSaga(action) {
    let errorMessage = 'Неверный логин или пароль'
    try{
        const resp = yield call(authAPI.getToken, {'username': action.login, 'password': action.password})
        const token = resp.data.token;
        setCookie('token', token);
        yield put({
            type: GET_TOKEN_SUCCESS,
        });
    }catch(error){
        if (error.message ==='Request failed with status code 400') {
            errorMessage = 'Неверный логин или пароль'
        } else if (error.message ==='Network Error') {
            errorMessage = 'Сервер не доступен'
        }else {
            errorMessage = error.message
        }
        yield put({
            type: GET_TOKEN_ERROR,
            error: errorMessage
        });
    }
}

function* getCodeSaga(action) {

    yield put({
        type: ASK_CODE_START,
    });
    try {
        yield call(authAPI.getCode);

        yield put({
            type: ASK_CODE_SUCCESS,

        });
    } catch (error) {

        yield put({
            type: ASK_CODE_ERROR,
            error: 'Невозможно отправить код'
        })

    }

}

function* checkCodeSaga(action) {
    let errorMessage = 'Неверный код СМС'
    try{
        if (action.retryCount > 0) {
            const res = yield call(authAPI.checkCode, action.code)
            // if (action.code === '111111') {
            if (res.status === 200) {
                yield put({
                    type: CHECK_CODE_SUCCESS,
                })
                yield put({
                    type: cabTypes.GET_STORGE_LIST,
                })
            } else {
                yield put({
                    type: CHECK_CODE_RETRY,
                    retryCount: action.retryCount - 1,
                    error: errorMessage
                })
            }
        } else {
            yield put({
                type: CHECK_CODE_ERROR,
                error: errorMessage
            })
        }


    } catch (error) {
        setCookie('0')
        if (error.message ==='Request failed with status code 403') {
            errorMessage = 'Неверный код СМС'
        } else if (error.message ==='Network Error') {
            errorMessage = 'Сервер не доступен'
        }else {
            errorMessage = error.message
        }
        yield put({
            type: CHECK_CODE_ERROR,
            error: errorMessage
        })
    }
}


export function* authSagas() {
    yield takeEvery(GET_TOKEN, getTokenSaga);
    yield takeEvery(GET_TOKEN_SUCCESS, getCodeSaga);
    yield takeEvery(CHECK_CODE_START, checkCodeSaga);
}