export const SEARCH_MODES = {
    'INN': {
        searchLabel: 'ИНН',
        fields: [{
            name: 'inn',
            placeholder: 'ИНН',
            title: 'ИНН',
            typeInput: 'text',
            required: true,
        }]
    },
    'OGRN': {
        searchLabel: 'ОГРН/ОГРНИП',
        fields: [{
            name: 'ogrn',
            placeholder: 'ОГРН/ОГРНИП',
            title: 'ОГРН/ОГРНИП',
            typeInput: 'text',
            required: true,
        }],
    },
    'AMOUNT_BIK': {
        searchLabel: 'Номер счета и БИК банка',
        fields: [{
            name: 'amount',
            placeholder: 'Номер счета',
            title: 'Номер счета',
            typeInput: 'text',
            required: true,
        }, {
            name: 'bik',
            placeholder: 'БИК банка',
            title: 'БИК банка',
            typeInput: 'text',
            required: true,
        }],
    },
    'NAME': {
        searchLabel: 'Наименование организации и ФИО ИП',
        fields: [{
            name: 'name',
            placeholder: 'Наименование',
            title: 'Наименование организации',
            typeInput: 'text',
            required: true,
        }],
    },
    'AMOUNT': {
        searchLabel: 'Номер счета',
        fields: [{
            name: 'amount',
            placeholder: 'Номер счета',
            title: 'Номер счета',
            typeInput: 'text',
            required: true,
        }],
    },
    'OPEN_DATES': {
        searchLabel: 'Диапазон дат открытия',
        fields: [{
            name: 'from',
            placeholder: '01.01.2021',
            title: 'От',
            typeInput: 'date',
            required: true,
        },{
            name: 'to',
            placeholder: '01.01.2022',
            title: 'До',
            typeInput: 'date',
            required: true,
        },
        ],
    },
    'CLOSE_DATES': {
        searchLabel: 'Диапазон дат закрытия',
        fields: [{
            name: 'from',
            placeholder: '01.01.2021',
            title: 'От',
            typeInput: 'date',
            required: true,
        },{
            name: 'to',
            placeholder: '01.01.2022',
            title: 'До',
            typeInput: 'date',
            required: true,
        },
        ],
    }
}