export const SEARCH_MODES =  {
    'GOSNUMBER': {
        searchLabel: 'Гос номер',
        fields:[{
            name: 'register_number',
            placeholder: 'Гос номер',
            title: 'Гос номер',
            typeInput: 'text',
            required: true,
            value: '',
        }],
    },
    'VIN': {
        searchLabel: 'VIN',
        fields: [{
            name: 'vin',
            placeholder: 'VIN',
            title: 'VIN',
            typeInput: 'text',
            required: true,
            value: '',
        }]
    },
    'BODY': {
        searchLabel: 'Номер кузова',
        fields: [{
            name: 'body_number',
            placeholder: 'Номер кузова',
            title: 'Номер кузова',
            typeInput: 'text',
            required: true,
            value: '',
        },]
    },

}