import React from "react";
import "./TransportDetails.module.scss";
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import {isInStorgeList} from "../../helpers/storgelist_handlers";



function convertOutputDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}


/**
 * @typedef SbsDetailsPropsType
 * @property {JSX.Element | string} children
 */

/**
 *
 * @param {SbsDetailsPropsType} props
 * @returns {JSX.Element}
 */

export const TransportDetails = (props) => {
    const ob = props.objectData
    const inStorageList = isInStorgeList(ob.id, props.storgeList)
    return (
        <div className="BoxesList">
            <ResultBox
                headerItems={
                    <React.Fragment>
                        <Button
                            additionalCssClass="_addToList"
                            onClick={!inStorageList
                                ? ()=> props.addInStorgeList(ob.id)
                                : () => props.deleteFromStorgeList(ob.id)}>
                            {!inStorageList ? 'Добавить в сторожевой лист' : 'Удалить из сторжевого листа'}
                        </Button>
                        <Button className="Close-button" onClick={props.onClose}>
                            <span aria-hidden="true">&times;</span>
                        </Button>
                    </React.Fragment>
                }
            >
                <FieldValueText
                    field="Госдарственный регистрационный знак: "
                    value={ob.gos_reg_znak}
                />
                <FieldValueText field="VIN: " value={ob.vin} />
                <FieldValueText field="Марка: " value={ob.model_ts} />
                <FieldValueText field="Год выпуска: " value={ob.year_of_release} />
                <FieldValueText field="Объем двигателя: " value={ob.volume_engine} />
                <FieldValueText field="Мощность лс: " value={ob.power_ls} />
                <FieldValueText field="Мощность КВт: " value={ob.power_kvt} />
                <FieldValueText field="Номер двигателя: " value={ob.engine_number} />
                <FieldValueText field="Номер кузова: " value={ob.body_number} />
                <FieldValueText field="Номер шасси: " value={ob.chassis_number} />
                {ob.ownerships && ob.ownerships.map((ows, idx) =>
                    <React.Fragment key={`item-${idx}`}>
                        <hr/>
                        <FieldValueText  field="Номер ПТС: " value={ows.pts_number} />
                        <FieldValueText  field="Дата регистрации владения: " value={convertOutputDate(ows.registration_date)} />
                        <FieldValueText  field="Дата прекращения владения: " value={convertOutputDate(ows.cancel_reg_date)} />
                        <FieldValueText  field="ФИО правообладателя: " value={ows.owner.full_name} />
                        <FieldValueText  field="ИНН: " value={ows.owner.inn} />
                        <FieldValueText  field="Номер и серия паспорта: " value={ows.owner.ul_number} />
                        <FieldValueText  field="Дата выдачи паспорта: " value={convertOutputDate(ows.owner.ul_date)} />
                        <FieldValueText  field="Дата рождения: " value={convertOutputDate(ows.owner.birth_date)} />
                        <FieldValueText  field="Дата смерти: " value={convertOutputDate(ows.owner.death_date)} />
                        <FieldValueText  field="Адрес места жительства:" value={ows.owner.address} />

                    </React.Fragment>

                )}

            </ResultBox>

        </div>
    );
};
