import {call, put, takeEvery} from "redux-saga/effects";
import { Types } from "./realty_action_types";
import {isDebug} from "../../../../constants/config";
import { realtyAPI} from "./realty_api";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";


function* sendRealtySearchRequest(action) {
    yield put({
        type: Types.SEND_REQUEST,
    })
    try {
        const resp = yield call(realtyAPI.search, action.searchParams);

        yield put({
            type: Types.SEARCH_SUCCESS,
            foundRealties: resp.data,
        });
    } catch(error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + message
        });
        yield put({
            type: Types.SEARCH_ERROR,
            error: error
        });
    }
}

function* getRealtyDetail(action) {
    yield put({
        type: Types.SEND_REQUEST_DETAIL,
    })
    try {
        const resp = yield call(realtyAPI.getDetail, action.id)

        let realtyObjectData = resp.data
        yield put({
            type: Types.SHOW_REALTY_DETAIL_SUCCESS,
            realtyObjectData: {...realtyObjectData}
        })
    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения данных объекта. ' + message
        });
        yield put({
            type: Types.SHOW_REALTY_DETAIL_ERROR,
            error: error
        });

    }
}


function* saveRequest(action) {
    yield put({
        type: Types.SAVE_REQUEST_START,
    })
    try{
        const resp = yield call(realtyAPI.saveRequest, action.id)
        if (isDebug) {
            console.log(resp.statusText)
        }
        yield put({
            type: Types.SAVE_REQUEST_SUCCESS,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка сохранения запроса. ' + message
        });
        yield put({
            type: Types.SAVE_REQUEST_ERROR,
            error: error
        });
    }
}

function* addInStorgeListsSaga(action) {

    try{
        yield call(realtyAPI.addInStorgeList, action.id)
        yield put({
            type: Types.ADD_IN_REALTY_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }

        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Не могу добавить в сторжевой лист. ' + message
        });
        yield put({
            type: Types.ADD_IN_REALTY_STORGE_LIST_ERROR,
            error: message
        });
    }
}

function* deleteFromStorgeListsSaga(action) {

    try{
        yield call(realtyAPI.deleteFromStorgeList, action.id)
        yield put({
            type: Types.DELETE_FROM_REALTY_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Не могу удалить из сторжевого листа. ' + message
        });
        yield put({
            type: Types.DELETE_FROM_REALTY_STORGE_LIST_ERROR,
            error: error
        });

    }
}

export function* realty_Sagas() {
    yield takeEvery(Types.REALTY_SEARCH, sendRealtySearchRequest);
    yield takeEvery(Types.SHOW_REALTY_DETAIL_START, getRealtyDetail);
    yield takeEvery(Types.SHOW_REALTY_DETAIL_START, saveRequest);
    yield takeEvery(Types.ADD_IN_REALTY_STORGE_LIST, addInStorgeListsSaga);
    yield takeEvery(Types.DELETE_FROM_REALTY_STORGE_LIST, deleteFromStorgeListsSaga)
}