import {
    OPEN_AUTH, OPEN_MANAGER_CABINET, OPEN_USER_CABINET_INFO,
} from "./index_action_types";
import { PAGES } from './data'


const initialState = {
    userInfo: null,
    showError: false,
    showPopup: false,
    popupMessage: '',
    error: null,
    activePage: PAGES.auth
}

const handlers = {
    [OPEN_USER_CABINET_INFO]: (state, action)=>({...state, activePage: PAGES.userCabinet, userInfo: action.userInfo}),
    [OPEN_MANAGER_CABINET]: (state, action)=>({...state, activePage: PAGES.managerCabinet,}),
    [OPEN_AUTH]: (state, action)=>({...state, activePage: PAGES.auth, userInfo: null}),

    DEFAULT: (state, action) => state
}




export function indexReducer(state=initialState, action='') {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}