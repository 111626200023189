import React from "react";
import {connect} from "react-redux";
import "../../ui/Form.css";
import {SearchResults} from "../../ui/SearchResults/SearchResults";
import {SEARCH_MODES} from "./data/constants"
import {SearchPanel} from "../../elements/search_panel"
import SearchItem from './search_item'
import {sbsActions} from "./services/sbs_actions";
import {isDebug} from "../../../constants/config";

class SBSSearchForm extends React.Component {

    state = {
        searchParams: {type: Object.values(SEARCH_MODES)[0].searchLabel},
        selectedMode: Object.keys(SEARCH_MODES)[0],

    }

    handleInputChange = (event) => {
        event.preventDefault();
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let sp = this.state.searchParams
        sp[name] = value
        this.setState({
            searchParams: sp
        });
        if (isDebug) {
            console.log(sp)

        }
    }

    setSelectedMode = (mode) => {
        this.setState({
            searchParams: {
                type: SEARCH_MODES[mode].searchLabel,
            },
            selectedMode: mode,
        });
    }

    startSearch = () => {
        this.props.submitSearchRequest(this.state.searchParams)
    }


    render() {
        return (
            <div>
                <SearchPanel
                    onSubmit={this.startSearch}
                    onChange={this.handleInputChange}
                    selectedMode={this.state.selectedMode}
                    setSelectedMode={this.setSelectedMode}
                    searchModes={SEARCH_MODES}
                />
                {this.props.loading &&
                    <div>
                        <h2>Идет поиск....</h2>
                    </div>
                }
                {(!!this.props.foundObjects && !this.props.loading) &&
                    <SearchResults title="Результаты поиска" count={"Найдено: " + this.props.foundObjects.length}>
                        {this.props.foundObjects.map((item, idx) => (
                            <SearchItem
                                key={`item-${idx}`}
                                item={item}
                                onClick={this.props.showSBSDetailForm}
                            />

                        ))}
                    </SearchResults>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        sendRequest: state.sbs.sendRequest,
        loading: state.sbs.loading,
        foundObjects: state.sbs.foundObjects
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showSBSDetailForm: id => dispatch(sbsActions.showDetailForm(id)),
        submitSearchRequest: searchParams => dispatch(sbsActions.search(searchParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SBSSearchForm);