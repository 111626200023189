import {
    Types
} from "./action_types";


const initialState = {
    showDetail: false,
    objectData: null,
    foundObjects: null,
    sendRequest: false,
    loading: false,
    error: null,
    searchParams: null,
    storgeList: null,
    updateSearch: false,

    foundPage: null,
    maxFoundPage: null,

    waitRequests: false,
    waitComments: false,
    requests: null,
    comments: null,
}


const handlers = {
    [Types.SAVE_AUTOZALOG_REQUEST_START]: (state, action) =>({...state, loading: false}),
    [Types.SAVE_AUTOZALOG_REQUEST_SUCCESS]: (state, action)=>({...state, loading: false}),
    [Types.SEND_AUTOZALOG_REQUEST]: (state, action) =>({...state, loading: true, foundObjects: null}),

    [Types.SHOW_AUTOZALOG_DETAIL_START]: (state, action)=>({...state, showError:false, error: action.error}),
    [Types.SHOW_AUTOZALOG_DETAIL_SUCCESS]: (state, action) =>({...state, loading: false, showDetail: true, objectData: action.objectData}),
    [Types.SHOW_AUTOZALOG_DETAIL_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [Types.CLOSE_AUTOZALOG_DETAIL]: (state, action)=>({...state, showDetail: false}),

    [Types.SEARCH_AUTOZALOG_START]: (state, action) =>({...state, loading: true, error: null}),
    [Types.SEARCH_AUTOZALOG_SUCCESS]: (state, action)=>({...state, loading: false, foundObjects: action.foundObjects}),
    [Types.SEARCH_AUTOZALOG_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),

    [Types.SHOW_AUTOZALOG_REQUESTS_START]: state =>({...state, error: null}),
    [Types.SHOW_AUTOZALOG_REQUESTS_SUCCESS]: (state, action)=>({...state, requests: action.requests}),
    [Types.SHOW_AUTOZALOG_REQUESTS_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [Types.SHOW_AUTOZALOG_COMMENTS_START]: state =>({...state, error: null}),
    [Types.SHOW_AUTOZALOG_COMMENTS_SUCCESS]: (state, action)=>({...state, comments: action.comments}),
    [Types.SHOW_AUTOZALOG_COMMENTS_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [Types.ADD_AUTOZALOG_COMMENT_SUCCESS]: (state, action)=>({...state, error: null}),
    [Types.ADD_AUTOZALOG_COMMENT_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),


    DEFAULT: state => state
}

export function autozlogReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}