import React from "react";
import {FieldValueText} from "../../ui/Typography/Typography";
import {Icon} from "../../ui/Icons/Icon";
import "./StorgeList.module.css";


export const TransportItem = (props) => {


    return (
        <div className="WatchListItem">
            <div className="WatchListItemInner">
                <div className="WatchListItemBody" onClick={() => props.onClick(props.item.object_id)}>
                    <FieldValueText field="VIN: " value={props.item.vin}/>
                    <FieldValueText field="Гос. рег. знак: " value={props.item.gos_reg_znak}/>
                    <FieldValueText field="Модель: " value={props.item.model_ts}/>
                    <FieldValueText field="Дата выпуска: " value={props.item.year_of_release}/>
                    <FieldValueText
                        field="Дата внесения: "
                        value={props.item.date}
                    />
                    <FieldValueText field="Зафиксировано запросов: " value={props.item.viewed_counter}/>
                </div>
                <div className="WatchListItemFooter">
              <span className="StyledLink _blue" onClick={() => (props.onDelete(props.item.object_id))}>
                <Icon style={{marginRight: 8}} type="trash"/>
                Убрать из листа
              </span>
                    <span className="WatchListItemBell">
                <Icon type="bell" modifier="_red"/>
                <span className="WatchListItemBellCount">{props.item.viewed_counter}</span>
              </span>
                </div>
            </div>
            {props.item.viewed_counter > 4 &&
                <React.Fragment>
                    <div className="WatchListItemNotification">
                        <div className="WatchListItemNotificationMessage">
                            Изменился адрес жительства
                        </div>
                        <Icon type="close"/>
                    </div>
                    <div className="WatchListItemNotification">
                        <div className="WatchListItemNotificationMessage">
                            Изменился адрес жительства
                        </div>
                        <Icon type="close"/>
                    </div>
                    <div className="WatchListItemNotification">
                        <div className="WatchListItemNotificationMessage">
                            Изменился адрес жительства
                        </div>
                        <Icon type="close"/>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}