import React from 'react'

const Test = () => {
    return (
        <h2>Тестовый компонент</h2>
    )
};

export function TestFunc(props) {
    return(
        <h1>{props.text || props || 'Тестовый компонент'}</h1>
    )
}

export default Test;