import {call, put, takeEvery} from "redux-saga/effects";
import {Types} from "./action_types";
import {jantarAPI} from "./api";


function* searchSaga(action) {
    yield put({
        type: Types.SEARCH_JANTAR_START,
    })
    try {
        const resp = yield call(jantarAPI.search, action.searchParams);

        yield put({
            type: Types.SEARCH_JANTAR_SUCCESS,
            foundObjects: resp.data,
        });
    } catch(error) {
        yield put({
            type: Types.SEARCH_JANTAR_ERROR,
            error: error
        });
    }
}

function* getDetail(action) {
    yield put({
        type: Types.SHOW_JANTAR_DETAIL_START,
    })
    try {
        const resp = yield call(jantarAPI.getDetail, action.id)

        yield put({
            type: Types.SHOW_JANTAR_DETAIL_SUCCESS,
            objectData: resp.data
        })
    } catch (error) {

        yield put({
            type: Types.SHOW_JANTAR_DETAIL_ERROR,
            error: error
        });

    }
}

// function* saveRequest(action) {
//     yield put({
//         type: Types.SAVE_JANTAR_REQUEST_START,
//     })
//     try{
//         const resp = yield call(jantarAPI.saveRequest, action.id)
//         if (isDebug) {
//             console.log(resp.statusText)
//         }
//         yield put({
//             type: Types.SAVE_JANTAR_REQUEST_SUCCESS,
//         });
//     }catch(error){
//         yield put({
//             type: Types.SAVE_JANTAR_REQUEST_ERROR,
//             error: error
//         });
//     }
// }
//
// function* addInStorgeListsSaga(action) {
//
//     try{
//         yield call(jantarAPI.addInStorgeList, action.id)
//         yield put({
//             type: Types.ADD_IN_JANTAR_STORGE_LIST_SUCCESS,
//         });
//         yield put({
//             type: cabTypes.GET_STORGE_LIST,
//         });
//     }catch(error){
//         yield put({
//             type: Types.ADD_IN_JANTAR_STORGE_LIST_ERROR,
//             error: error
//         });
//     }
// }
//
// function* deleteFromStorgeListsSaga(action) {
//
//     try{
//         yield call(jantarAPI.deleteFromStorgeList, action.id)
//         yield put({
//             type: Types.DELETE_FROM_JANTAR_STORGE_LIST_SUCCESS,
//         });
//         yield put({
//             type: cabTypes.GET_STORGE_LIST,
//         });
//     }catch(error){
//         yield put({
//             type: Types.DELETE_FROM_JANTAR_STORGE_LIST_ERROR,
//             error: error
//         });
//     }
// }

export function* jantarSagas() {
    yield takeEvery(Types.JANTAR_SEARCH, searchSaga);
    yield takeEvery(Types.SEND_JANTAR_REQUEST_DETAIL, getDetail);
    // yield takeEvery(Types.SHOW_JANTAR_DETAIL_SUCCESS, saveRequest);
    // yield takeEvery(Types.ADD_IN_JANTAR_STORGE_LIST, addInStorgeListsSaga);
    // yield takeEvery(Types.DELETE_FROM_JANTAR_STORGE_LIST, deleteFromStorgeListsSaga);
}