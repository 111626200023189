import {Types } from "./realty_action_types";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";


const initialState = {
    showRealtyDetail: false,
    realtyObjectData: null,
    foundRealties: null,
    sendRequest: false,
    loading: false,
    error: null,
    searchParams: null,
    //realtySearchForm
    inStorageList: false,
    updateSearch: false,
}

const handlers = {
    [Types.SAVE_REQUEST_START]: (state, action) => ({...state, loading: false }),
    [Types.SAVE_REQUEST_SUCCESS]: (state, action) => ({...state, loading: false }),
    [Types.SAVE_REQUEST_ERROR]: (state, action) => ({...state, loading: false, error: action.error}),
    [Types.SEND_REQUEST]: (state, action) => ({...state, loading: true, foundRealties: null }),
    [Types.SEND_REQUEST_DETAIL]: (state, action) => ({...state, loading: true }),
    [Types.SHOW_REALTY_DETAIL_SUCCESS]: (state, action) => ({...state, loading: false, showRealtyDetail: true,
        realtyObjectData: action.realtyObjectData}),
    [Types.SHOW_REALTY_DETAIL_ERROR]: (state, action) => ({...state, loading: false, error: action.error}),
    [Types.CLOSE_REALTY_DETAIL]: (state, action) => ({...state, loading: false, showRealtyDetail: false, updateSearch: true }),
    [Types.SEARCH_SUCCESS]: (state, action) => ({...state, loading: false, foundRealties: action.foundRealties}),
    [Types.SEARCH_ERROR]: (state, action) => ({...state, loading: false, error: action.error }),
    [cabTypes.GET_STORGE_LIST_SUCCESS]: (state, action)=>({...state, storgeList: action.realtyIDS}),
    DEFAULT: state => state
}

export function realtyReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}