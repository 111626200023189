import React from 'react';
import {connect} from "react-redux";

import JantarSearchForm from './jantar_search';
import {jantarActions} from "./services/actions";
import {JantarDetails} from "./jantar_detail";


class Jantar extends React.Component {
    renderSearchTab = () => {
        if (this.props.showDetail) {
            //TODO передать статус объекта
            return <JantarDetails
                onClose={this.props.closeDetail}
                objectData={this.props.objectData}
                inStorageList={false}
                addInStorgeList={this.props.addInStorgeList}
                deleteFromStorgeList={this.props.deleteFromStorgeList}
                storgeList={this.props.storgeList}
            />;
        } else {
            return <JantarSearchForm />;
        }
    }

    updateResult = (x, y, z) => {
        this.setState({
            resultData: x,
            searchQuery: y,
            searchUrl: z,
        });
        if (x.length > 10) {
            this.setState({maxFoundPage: x.length / 10, foundPage: 1})
        } else {
            this.setState({maxFoundPage: x.length / 10, foundPage: 1})
        }

    };

    render() {
        return (this.renderSearchTab())
    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.jantar.showDetail,
        objectData: state.jantar.objectData,
        foundObjects: state.jantar.foundObjects,
        storgeList: state.jantar.storgeList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(jantarActions.closeDetail()),
        addInStorgeList: (id) => dispatch(jantarActions.addInStorgeList(id)),
        deleteFromStorgeList: (id) => dispatch(jantarActions.deleteFromStorgeList(id)),
        //TODO getPDF()
        //TODO
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jantar);