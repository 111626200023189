import {call, put, takeEvery, takeLeading, takeLatest} from "redux-saga/effects";

import {
} from "./sbs_actions";
import {isDebug} from "../../../../constants/config";
import {sbsAPI} from "./sbs_api";
import {
    ADD_IN_SBS_STORGE_LIST,
    ADD_IN_SBS_STORGE_LIST_ERROR,
    ADD_IN_SBS_STORGE_LIST_SUCCESS,
    ADD_SBS_COMMENT_ERROR,
    ADD_SBS_COMMENTS,
    DELETE_FROM_SBS_STORGE_LIST,
    DELETE_FROM_SBS_STORGE_LIST_ERROR,
    DELETE_FROM_SBS_STORGE_LIST_SUCCESS,
    REQUEST_MADE_ERROR,
    REQUEST_MADE_START,
    REQUEST_MADE_SUCCESS,
    SBS_SEARCH_ERROR,
    SBS_SEARCH_START,
    SBS_SEARCH_SUCCESS,
    SBS_SEND_SEARCH_REQUEST,
    SHOW_SBS_AMOUNTS,
    SHOW_SBS_AMOUNTS_ERROR,
    SHOW_SBS_AMOUNTS_START,
    SHOW_SBS_AMOUNTS_SUCCESS,
    SHOW_SBS_COMMENTS,
    SHOW_SBS_COMMENTS_ERROR,
    SHOW_SBS_COMMENTS_START,
    SHOW_SBS_COMMENTS_SUCCESS,
    SHOW_SBS_DETAIL,
    SHOW_SBS_DETAIL_ERROR,
    SHOW_SBS_DETAIL_START,
    SHOW_SBS_DETAIL_SUCCESS,
    SHOW_SBS_REQUESTS,
    SHOW_SBS_REQUESTS_ERROR,
    SHOW_SBS_REQUESTS_START,
    SHOW_SBS_REQUESTS_SUCCESS
} from "./sbs_action_types";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";


export function* sbsSagas() {
    yield takeEvery(SBS_SEND_SEARCH_REQUEST, sendSBSSearchRequest);
    yield takeEvery(SHOW_SBS_DETAIL, getSbsDetail);
    yield takeEvery(SHOW_SBS_DETAIL, saveRequest);
    yield takeLatest(SHOW_SBS_AMOUNTS, getAmountsSaga);
    yield takeLeading(SHOW_SBS_REQUESTS, getRequestsSaga);
    yield takeLeading(SHOW_SBS_COMMENTS, getCommentsSaga);
    yield takeLeading(ADD_SBS_COMMENTS, postCommentSaga);
    yield takeEvery(ADD_IN_SBS_STORGE_LIST, addInStorgeListsSaga);
    yield takeEvery(DELETE_FROM_SBS_STORGE_LIST, deleteFromStorgeListsSaga);
}


function* sendSBSSearchRequest(action) {
    yield put({
        type: SBS_SEARCH_START,
    })
    try {
        const resp = yield call(sbsAPI.search, action.searchParams);

        yield put({
            type: SBS_SEARCH_SUCCESS,
            foundObjects: resp.data,
        });
    } catch(error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + message
        });
        yield put({
            type: SBS_SEARCH_ERROR,
            error: error
        });
    }
}

function* getSbsDetail(action) {
    yield put({
        type: SHOW_SBS_DETAIL_START,
    })
    try {
        const resp = yield call(sbsAPI.getDetail, action.id)
        yield put({
            type: SHOW_SBS_DETAIL_SUCCESS,
            sbsObjectData: resp.data
        })
    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка полученя данных объекта. ' + message
        });
        yield put({
            type: SHOW_SBS_DETAIL_ERROR,
            error: error
        });

    }
}

function* saveRequest(action) {
    yield put({
        type: REQUEST_MADE_START,
    })
    try{
        const resp = yield call(sbsAPI.requestMade, action.id)
        if (isDebug) {
            console.log(resp.statusText)
        }
        yield put({
            type: REQUEST_MADE_SUCCESS,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка сохранения запроса. ' + message
        });
        yield put({
            type: REQUEST_MADE_ERROR,
            error: error
        });
    }
}

function* getAmountsSaga(action) {
    yield put({
        type: SHOW_SBS_AMOUNTS_START,
    })
    try{
        const resp = yield call(sbsAPI.getAmounts, action.id)
        if (isDebug) {
            console.log(resp.statusText)
        }
        yield put({
            type: SHOW_SBS_AMOUNTS_SUCCESS,
            amounts: resp.data
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения счетов. ' + message
        });
        yield put({
            type: SHOW_SBS_AMOUNTS_ERROR,
            error: error
        });
    }
}

function* getRequestsSaga(action) {
    yield put({
        type: SHOW_SBS_REQUESTS_START,
    })
    try{
        const resp = yield call(sbsAPI.getRequests, action.id)
        yield put({
            type: SHOW_SBS_REQUESTS_SUCCESS,
            requests: resp.data
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения запросов. ' + message
        });
        yield put({
            type: SHOW_SBS_REQUESTS_ERROR,
            error: error
        });
    }
}

function* getCommentsSaga(action) {
    yield put({
        type: SHOW_SBS_COMMENTS_START,
    })
    try{
        const resp = yield call(sbsAPI.getComments, action.id)
        yield put({
            type: SHOW_SBS_COMMENTS_SUCCESS,
            comments: resp.data
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения комментариев. ' + message
        });
        yield put({
            type: SHOW_SBS_COMMENTS_ERROR,
            error: error
        });
    }
}

function* postCommentSaga(action) {
    let sleep = ms => new Promise(res=>setTimeout(res,ms));
    try{
        yield call(sbsAPI.postComment, action.id, action.comment);
        sleep(2000);
        yield put({
            type: SHOW_SBS_COMMENTS,
        })
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка сохранения комментария. ' + message
        });
        yield put({
            type: ADD_SBS_COMMENT_ERROR,
            error: error
        });
    }
}

function* addInStorgeListsSaga(action) {

    try{
        const res = yield call(sbsAPI.addInStorgeList, action.id)
        console.log('res.data: ', res.data)
        yield put({
            type: ADD_IN_SBS_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка добавления с торжевой лист. ' + message
        });
        yield put({
            type: ADD_IN_SBS_STORGE_LIST_ERROR,
            error: error
        });
    }
}

function* deleteFromStorgeListsSaga(action) {
    try{
        yield call(sbsAPI.deleteFromStorgeList, action.id)
        yield put({
            type: DELETE_FROM_SBS_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка удаления из сторжевого листа. ' + message
        });
        yield put({
            type: DELETE_FROM_SBS_STORGE_LIST_ERROR,
            error: error
        });
    }
}

