import React from 'react';
import {connect} from "react-redux";

import ChecksSearchForm from './search';
import {checksActions} from "./services/actions";
import {ChecksDetails} from "./detail";

// import {isInStorgeList} from "../../helpers/helpers"


class Checks extends React.Component {
    renderSearchTab = () => {
        if (this.props.showDetail) {
            return <ChecksDetails
                onClose={this.props.closeDetail}
                objectData={this.props.objectData}
                inStorageList={false}
                addInStorgeList={this.props.addInStorgeList}
                deleteFromStorgeList={this.props.deleteFromStorgeList}
                storgeList={this.props.storgeList}
            />;
        } else {
            return <ChecksSearchForm/>;
        }
    }

    updateResult = (x, y, z) => {
        this.setState({
            resultData: x,
            searchQuery: y,
            searchUrl: z,
        });
        if (x.length > 10) {
            this.setState({maxFoundPage: x.length / 10, foundPage: 1})
        } else {
            this.setState({maxFoundPage: x.length / 10, foundPage: 1})
        }

    };

    render() {
        return (this.renderSearchTab())
    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.checks.showDetail,
        objectData: state.checks.objectData,
        foundObjects: state.checks.foundObjects,
        storgeList: state.checks.storgeList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(checksActions.closeDetail()),
        addInStorgeList: (id) => dispatch(checksActions.addInStorgeList(id)),
        deleteFromStorgeList: (id) => dispatch(checksActions.deleteFromStorgeList(id)),
        //TODO getPDF()
        //TODO
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checks);