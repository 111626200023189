import {cabTypes} from "./cabinet_action_types";
import {cabinetsData, SBS, RFPI, AMBER, CARS, REALTY, TRANSPORT, BOT, CHECKS} from "./data/cabinets";
import { STORGE_LIST, USER_REFERENCE, SUPPORT, CABINET} from "./data/menu"


import { sidebarMenuItems } from "./data/menu";

const initialState = {
    userInfo: null,
    showError: false,
    showPopupError: false,
    showPopupWindow: false,
    popupMessage: '',
    errorMessage: '',
    error: null,
    activeWindow: CABINET,
    cabinetTitle: 'Личный кабинет',
    sidebarMenuItems: {...sidebarMenuItems},
    cabinets: {...cabinetsData},
    onSideBar: true,
    askApprovalUserData: false,
    delayApprovedUserData: false,
    foundUsers: null,
    storgeList: null,
    showDetail: false,
    storgeDetailComponent: null
}

const handlers = {
    [cabTypes.SHOW_POPUP]: (state, action)=>({...state, showPopup: true,  popupMessage: action.message}),
    [cabTypes.CLOSE_POPUP]: (state, action)=>({...state, showPopup: false,  popupMessage: '', }),
    [cabTypes.SHOW_ERROR]: (state, action)=>({...state, showError: true,  error: action.error}),
    [cabTypes.CLOSE_ERROR]: (state, action)=>({...state, showError: false, error: null}),

    [cabTypes.GET_USER_INFO_START]: state =>({...state, error: null}),
    [cabTypes.GET_USER_INFO_SUCCESS]: (state, action)=>({...setPermissions(state, action.userInfo), userInfo: action.userInfo, askApprovalUserData: !action.userInfo.approve_personal_info}),
    [cabTypes.GET_USER_INFO_ERROR]: (state, action)=>({...state, loading: false, showError:true, error: action.error}),
    [cabTypes.APPROVE_USER_INFO_SUCCESS]: (state, action)=>({...state, askApprovalUserData: false, delayApprovedUserData: true}),
    [cabTypes.APPROVE_USER_INFO_ERROR]: (state, action)=>({...state, delayApprovedUserData: true}),
    [cabTypes.DELAY_APPROVED]: (state, action)=>({...state, askApprovalUserData: false, delayApprovedUserData: true}),

    [cabTypes.OPEN_CABINET]: (state, action)=>({...state, onSideBar: true,  activeWindow: CABINET, cabinetTitle: 'Личный кабинет', error: null}),
    [cabTypes.OPEN_SUPPORT]: (state, action)=>({...state, onSideBar: true,  activeWindow: SUPPORT, cabinetTitle: 'Техподдержка', error: null}),
    [cabTypes.OPEN_USER_REFERENCE]: (state, action)=>({...state, onSideBar: true,  activeWindow: USER_REFERENCE, cabinetTitle: 'Справочник пользователей', error: null}),
    [cabTypes.OPEN_STORGE_LIST]: (state, action)=>({...state, onSideBar: true,  activeWindow: STORGE_LIST, cabinetTitle: 'Сторжевой лист', error: null}),
    [cabTypes.OPEN_SBS]: (state, action)=>({...state, onSideBar: false, activeWindow: SBS, cabinetTitle: 'СБС', error: null}),
    [cabTypes.OPEN_RFPI]: (state, action)=>({...state, onSideBar: false, activeWindow: RFPI, cabinetTitle: 'РФПИ', error: null}),
    [cabTypes.OPEN_AMBER]: (state, action)=>({...state, onSideBar: false, activeWindow: AMBER, cabinetTitle: 'Янтарь', error: null}),
    [cabTypes.OPEN_CARS]: (state, action)=>({...state, onSideBar: false, activeWindow: CARS, cabinetTitle: 'Автозалог', error: null}),
    [cabTypes.OPEN_REALTY]: (state, action)=>({...state, onSideBar: false, activeWindow: REALTY,cabinetTitle: 'Недвижимость', error: null}),
    [cabTypes.OPEN_TRANSPORT]: (state, action)=>({...state, onSideBar: false, activeWindow: TRANSPORT, cabinetTitle: 'Транспорт', error: null}),
    [cabTypes.OPEN_CHECKS]: (state, action)=>({...state, onSideBar: false, activeWindow: CHECKS, cabinetTitle: 'Проверки', error: null}),
    [cabTypes.OPEN_BOT_CLIENT]: (state, action)=>({...state, onSideBar: false, activeWindow: BOT, cabinetTitle: 'Бот клиент>', error: null}),

    [cabTypes.SEND_SUPPORT_MESSAGE]: (state, action)=>({...state, cabinetTitle: 'Техподдержка', error: null}),
    [cabTypes.SEND_MESSAGE_SUCCESS]: (state, action)=>({...state}),
    [cabTypes.LOGOUT]: (state, action)=>({...state, userInfo: null}),
    [cabTypes.SEND_MESSAGE_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),

    [cabTypes.FIND_USER_INFO_START]: state =>({...state, loading: true, error: null}),
    [cabTypes.FIND_USER_INFO_SUCCESS]: (state, action)=>({...state, foundUsers: action.foundUsers, loading: false}),
    [cabTypes.FIND_USER_INFO_ERROR]: (state, action)=>({...state, showError:true, error: action.error, loading: false}),

    [cabTypes.GET_STORGE_LIST_SUCCESS]: (state, action)=>({...state, storgeList: action.storgeList, loading: false}),
    [cabTypes.GET_STORGE_LIST_ERROR]: (state, action)=>({...state, showError:true, error: action.error, loading: false}),

    [cabTypes.SHOW_POPUP_WINDOW]: (state, action)=>({...state, showPopupWindow:true, popupMessage: action.message}),
    [cabTypes.CLOSE_POPUP_WINDOW]: (state, action)=>({...state, showPopupWindow:false, popupMessage: ''}),

    [cabTypes.SHOW_POPUP_ERROR]: (state, action)=>({...state, showPopupError:true, errorMessage: action.message}),
    [cabTypes.CLOSE_POPUP_ERROR]: (state, action)=>({...state, showPopupError:false, errorMessage: ''}),


    DEFAULT: state => state
}

function setPermissions(state, userInfo) {
    let newState = {...state};
    let userReferenceAccess = newState.sidebarMenuItems.USER_REFERENCE;

    newState.userInfo = userInfo;
    newState.sidebarMenuItems.USER_REFERENCE.show = userInfo[userReferenceAccess.accessField];
    newState.askApprovalUserData = userInfo.approval_date

    for (let key in newState.cabinets) {
        newState.cabinets[key].enabled = userInfo[newState.cabinets[key].accessField]
    }
    return newState
}

export function cabinetReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}