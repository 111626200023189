import {call, put, takeEvery} from "redux-saga/effects";
import {
    Types
} from "./transport_action_types";
import {isDebug} from "../../../../constants/config";
import {transportAPI} from "./transport_api";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";


function* transportSearchSaga(action) {
    yield put({
        type: Types.SEARCH_TRANSPORT_START,
    })
    try {
        const resp = yield call(transportAPI.search, action.searchParams);

        yield put({
            type: Types.SEARCH_TRANSPORT_SUCCESS,
            foundObjects: resp.data,
        });
    } catch(error) {
        yield put({
            type: Types.SEARCH_TRANSPORT_ERROR,
            error: error
        });
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + error
        });
    }
}

function* getTransportDetail(action) {
    yield put({
        type: Types.SHOW_TRANSPORT_DETAIL_START,
    })
    try {
        const resp = yield call(transportAPI.getDetail, action.id)

        yield put({
            type: Types.SHOW_TRANSPORT_DETAIL_SUCCESS,
            objectData: resp.data
        })
    } catch (error) {

        yield put({
            type: Types.SHOW_TRANSPORT_DETAIL_ERROR,
            error: error
        });
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Не могу удалить из сторжевого листа. ' + message
        });
    }
}

function* saveRequest(action) {
    yield put({
        type: Types.SAVE_TRANSPORT_REQUEST_START,
    })
    try{
        const resp = yield call(transportAPI.saveRequest, action.id)
        if (isDebug) {
            console.log(resp.statusText)
        }
        yield put({
            type: Types.SAVE_TRANSPORT_REQUEST_SUCCESS,
        });
    }catch(error){
        yield put({
            type: Types.SAVE_TRANSPORT_REQUEST_ERROR,
            error: error
        });
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка сохранения запроса. ' + message
        });
    }
}

function* addInStorgeListsSaga(action) {

    try{
        yield call(transportAPI.addInStorgeList, action.id)
        yield put({
            type: Types.ADD_IN_TRANSPORT_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }

        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Не могу добавить в сторжевой лист. ' + message
        });
        yield put({
            type: Types.ADD_IN_TRANSPORT_STORGE_LIST_ERROR,
            error: message
        });
    }
}

function* deleteFromStorgeListsSaga(action) {

    try{
        yield call(transportAPI.deleteFromStorgeList, action.id)
        yield put({
            type: Types.DELETE_FROM_TRANSPORT_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Не могу удалить из сторжевого листа. ' + message
        });
        yield put({
            type: Types.DELETE_FROM_TRANSPORT_STORGE_LIST_ERROR,
            error: error
        });

    }
}

export function* transportSagas() {
    yield takeEvery(Types.TRANSPORT_SEARCH, transportSearchSaga);
    yield takeEvery(Types.SEND_TRANSPORT_REQUEST_DETAIL, getTransportDetail);
    yield takeEvery(Types.SEND_TRANSPORT_REQUEST_DETAIL, saveRequest);
    yield takeEvery(Types.ADD_IN_TRANSPORT_STORGE_LIST, addInStorgeListsSaga);
    yield takeEvery(Types.DELETE_FROM_TRANSPORT_STORGE_LIST, deleteFromStorgeListsSaga);
}