import React from 'react';
import "./auth.css"
import "../ui/Form.css"
import "../ui/index.css"
import {Logo} from "../ui/Logo/Logo";
import {DolphinIcon} from "../ui/Icons/DolphinIcon";
import {Input} from "../ui/Input/Input";
import {Button} from "../ui/Button/Button";
import {connect} from "react-redux";
import {checkCode, getToken} from "./services/auth_actions";
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'


class Auth extends React.Component {

    constructor(props) {
        super(props)
        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeSMSCod = this.handleChangeSMSCod.bind(this);

    }

    state = {
        login: "",
        password: "",
        isCorrect: null,
        smskod: "",
        capsLockEnabled: null
    }

    handleChangeLogin(event) {
        this.setState({
            login: event.target.value,
        });
    }

    handleChangePassword(event) {
        this.setState({
            password: event.target.value,
        });
    }

    handleChangeSMSCod(event) {
        this.setState({
            smskod: event.target.value,
        });
    }

    onAuth = (event) => {
        event.preventDefault();
        const login = this.state.login;
        const password = this.state.password;
        this.setState({
            smskod: "",
            login: "",
            password: "",
        });
        this.props.getToken(login, password)
    }


    onApprove = (event) => {
        event.preventDefault();
        this.props.checkCode(this.state.smskod, this.props.retryCount)
    }


    render() {
        return (
            <div className="authContainer">
                <div className="authForm">
                    <Logo type="big"/>
                    <div className="AuthFormHeaderWrap">
                        <h1 className="AuthFormHeader">Информационно-поисковая система</h1>
                        <div className="FormField">
                            <DolphinIcon style={{marginRight: 8}}/> Дельфин - онлайн
                        </div>
                    </div>
                    {!this.props.showCodeConfirm ?
                        <form className="Form" onSubmit={this.onAuth}>
                            <div className="FormField">
                                <Input
                                    value={this.state.login}
                                    placeholder="Введите логин"
                                    onChange={this.handleChangeLogin}
                                    type="text"/>
                            </div>
                            <div className="FormField">
                                <Input
                                    value={this.state.password}
                                    placeholder="Введите пароль"
                                    onChange={this.handleChangePassword}
                                    type="password"
                                />
                                <ReactIsCapsLockActive>
                                    {active => active &&
                                        <span className="error">Caps lock активен</span>
                                    }
                                </ReactIsCapsLockActive>
                                {this.props.showError &&
                                    <span className="error">{this.props.error}</span>
                                }

                            </div>

                            <div className="FormField">
                                <Button type="submit">войти</Button>
                            </div>
                        </form>
                        :
                        <form className="Form" onSubmit={this.onApprove}>
                            <div className="FormField">
                                <Input
                                    value={this.state.smskod}
                                    placeholder="Код СМС"
                                    onChange={this.handleChangeSMSCod}
                                    type="text"
                                />
                            </div>
                            {this.props.showError &&
                                <span className="error">{this.props.error}</span>
                            }
                            <div className="FormField">
                                <Button type="submit">отправить</Button>
                            </div>
                        </form>
                    }
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    // console.log("state: ", state)
    return {
        showCodeConfirm: state.auth.showCodeConfirm,
        showError: state.auth.showError,
        error: state.auth.error,
        retryCount: state.auth.retryCount,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getToken: (login, password) => dispatch(getToken(login, password)),
        checkCode: (smscode, retryCount) => dispatch(checkCode(smscode, retryCount))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);