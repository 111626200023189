import React from "react";
import {connect} from "react-redux";
import "../ui/Form.css";
import {findUsers} from "../pages/services/cabinet/cabinet_actions";
import {SearchPanel} from "../elements/search_panel";

const SEARCH_MODES = {
    'ENTITY': {
        searchLabel: 'Организация',
        fields: [{
            name: 'entity',
            placeholder: '123456789123',
            title: 'Название',
            typeInput: 'text',
            required: true,
        }]
    },
    'FIO': {
        searchLabel: 'ФИО',
        fields: [{
            name: 'last_name',
            placeholder: 'Фамилия',
            title: 'Фамилия',
            typeInput: 'text',
            required: false,
            value: '',
        },{
            name: 'first_name',
            placeholder: 'Имя',
            title: 'Имя',
            typeInput: 'text',
            required: false,
            value: '',
        },{
            name: 'middle_name',
            placeholder: 'Отчество',
            title: 'Отчество',
            typeInput: 'text',
            required: false,
            value: '',
        }]
    }
};

class UserReference extends React.Component {

    state = {
        searchParams: {type: Object.values(SEARCH_MODES)[0].searchLabel},
        selectedMode: Object.keys(SEARCH_MODES)[0],
    }

    handleInputChange = (event) => {
        event.preventDefault();
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let sp = this.state.searchParams
        sp[name] = value
        this.setState({
            searchParams: sp
        })
    }

    setSelectedMode = (mode) => {
        this.setState({
            searchParams: {
                type: SEARCH_MODES[mode].searchLabel,
            },
            selectedMode: mode,

        });
    }

    startSearch = () => {
        this.props.search(this.state.searchParams)
    }

    // TODO Добавить вывод ошибки поиска

    render() {
        return (
            <div>
                <SearchPanel
                    onSubmit={this.startSearch}
                    onChange={this.handleInputChange}
                    selectedMode={this.state.selectedMode}
                    setSelectedMode={this.setSelectedMode}
                    searchModes={SEARCH_MODES}
                />
                {this.props.loading &&
                    <div>
                        <h2>Идет поиск....</h2>
                    </div>
                }
                {(!!this.props.foundUsers && !this.props.loading) &&
                    <React.Fragment>
                        <table className="RequestsTable" cellPadding="0" cellSpacing="0">
                            <thead>
                            <tr>
                                <th>Организация</th>
                                <th>ФИО</th>
                                <th>Телефон</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.foundUsers.map((item, idx) => (
                                <tr key={`item-${idx}`}>
                                    <th>{item.entity_name}</th>

                                    <td>{item.full_name}</td>
                                    <td>{item.phone}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </React.Fragment>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        loading: state.cabinet.loading,
        foundUsers: state.cabinet.foundUsers,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        search: searchParams => dispatch(findUsers(searchParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserReference);