import axios from "axios";
import {postAxiosAPI, getAxiosAPI, deleteAxiosAPI} from "../../../helpers/axios_helpers";
import qs from 'qs';
import { getCookie } from 'react-use-cookie';

export const sbsAPI = {
    search: searchRequest,
    getDetail: getSbsDetailRequest,
    getAmounts: getAmounts,
    getRequests: getRequests,
    getComments: getComments,
    postComment: postComment,
    requestMade: requestMade,
    deleteFromStorgeList,
    addInStorgeList,
}


async function searchRequest(searchParams) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'dolphin_sbs/search/',
        data: qs.stringify(searchParams),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function getSbsDetailRequest(id){
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'dolphin_sbs/entity/'+ id + '/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function getAmounts(entityID) {
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'dolphin_sbs/amounts/?entity_id='+ entityID,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function requestMade(entityID) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'dolphin_sbs/request_made/',
        data: qs.stringify({'checked_object': entityID}),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}


async function getRequests(entityID) {
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'dolphin_sbs/request_made/?entity_id='+ entityID,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function getComments(entityID) {
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'dolphin_sbs/comments/?entity_id='+ entityID,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function postComment(entityID, comment) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'dolphin_sbs/comments/',
        data: qs.stringify({
            'text': comment,
            'commented_object': entityID
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function addInStorgeList(entityID) {
    console.log('API entity id: ', entityID)
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'dolphin_sbs/tracked_list/',
        data: qs.stringify({
            'tracked_object': entityID
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function deleteFromStorgeList(entityID) {
    const resp = await axios({
        ...deleteAxiosAPI,
        url: deleteAxiosAPI.url + `dolphin_sbs/tracked_list/${entityID}/`,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}