export const Types = {
    SEND_JANTAR_REQUEST: 'SEND_JANTAR_REQUEST',
    SEND_JANTAR_REQUEST_DETAIL: 'SEND_JANTAR_REQUEST_DETAIL',
    JANTAR_SEARCH: 'JANTAR_SEARCH',
    SAVE_JANTAR_REQUEST_START: 'SAVE_JANTAR_REQUEST_START',
    SAVE_JANTAR_REQUEST_SUCCESS: 'SAVE_JANTAR_REQUEST_SUCCESS',
    SAVE_JANTAR_REQUEST_ERROR: 'SAVE_JANTAR_REQUEST_ERROR',
    CLOSE_JANTAR_DETAIL: 'CLOSE_JANTAR_DETAIL',
    SHOW_JANTAR_DETAIL_START: 'SHOW_JANTAR_DETAIL_START',
    SHOW_JANTAR_DETAIL_SUCCESS: 'SHOW_JANTAR_DETAIL_SUCCESS',
    SHOW_JANTAR_DETAIL_ERROR: 'SHOW_JANTAR_DETAIL_ERROR',
    SEARCH_JANTAR_START: 'SEARCH_JANTAR_START',
    SEARCH_JANTAR_SUCCESS: 'SEARCH_JANTAR_SUCCESS',
    SEARCH_JANTAR_ERROR: 'SEARCH_JANTAR_ERROR',

    ADD_IN_JANTAR_STORGE_LIST: 'ADD_IN_JANTAR_STORGE_LIST',
    ADD_IN_JANTAR_STORGE_LIST_SUCCESS: 'ADD_IN_JANTAR_STORGE_LIST_SUCCESS',
    ADD_IN_JANTAR_STORGE_LIST_ERROR: 'ADD_IN_JANTAR_STORGE_LIST_ERROR',
    DELETE_FROM_JANTAR_STORGE_LIST: 'DELETE_FROM_JANTAR_STORGE_LIST',
    DELETE_FROM_JANTAR_STORGE_LIST_SUCCESS: 'DELETE_FROM_JANTAR_STORGE_LIST_SUCCESS',
    DELETE_FROM_JANTAR_STORGE_LIST_ERROR: 'DELETE_FROM_JANTAR_STORGE_LIST_ERROR',
}