import {call, put, takeEvery} from "redux-saga/effects";
import {
    OPEN_AUTH,
    OPEN_USER_CABINET, OPEN_USER_CABINET_INFO
} from "./index_action_types";
import {cabTypes} from "../cabinet/cabinet_action_types";
import { getCookie } from 'react-use-cookie';
import {indexAPI} from "./index_api";

function* openPersonalCabinetSaga(action) {
    yield put({
        type: OPEN_USER_CABINET_INFO,
        userInfo: action.userInfo
    })
}

function* exitSaga(action) {

    try {
        yield call(indexAPI.logout);
        yield put({
            type: OPEN_AUTH,
        });
        getCookie('token')
    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка выхода. ' + message
        });
    }

}

export function* indexSagas() {
    yield takeEvery(OPEN_USER_CABINET, openPersonalCabinetSaga);
    yield takeEvery(cabTypes.LOGOUT, exitSaga);
}