import {
  openSBS, openRFPI, openCars,
  openAmber, openRealty, openTransport, openChecks, openBotClient,
} from "../cabinet_actions"
import Realty from "../../../../cabinets/Realty";
import SBSComponent from "../../../../cabinets/SBS/"
import Transport from "../../../../cabinets/Transport";
import RFPIComponent from "../../../../cabinets/RFPI";
import Jantar from "../../../../cabinets/Jantar";
import Autozalog from "../../../../cabinets/Autozalog";
import Checks from "../../../../cabinets/Checks";
import BotClient from "../../../../cabinets/BotClient";

export const SBS = 'SBS';
export const RFPI = 'RFPI';
export const AMBER = 'AMBER';
export const CARS = 'CARS';
export const REALTY = 'REALTY';
export const TRANSPORT = 'TRANSPORT';
export const CHECKS = 'CHECKS';
export const BOT = 'BOT';


export const cabinetsData = {
  [SBS]: {
      title: 'СБС',
      id: 1,
      enabled: false,
      description: 'Модуль с данными по банковским счетам',
      dateCurrentData: '01.01.2007 по 01.01.2018',
      showWatchlist: true,
      showSearch: true,
      accessField: "sbs_access",
      onClick: openSBS,
      component: SBSComponent,
    }, 
    [RFPI]: {
      title: 'РФПИ',
      id: 2,
      enabled: false,
      description: 'Модуль с данными по физ. лицам',
      dateCurrentData: '01.01.2007 по 01.01.2018',
      showWatchlist: true,
      showSearch: true,
      accessField: "rfpi_access",
      onClick: openRFPI,
      component: RFPIComponent,
    }, 
    [CARS]: {
      title: 'АВТОЗАЛОГ',
      id: 3,
      enabled: false,
      description: 'Модуль с данными по залогам автотранспорта',
      dateCurrentData: '01.01.2007 по 01.01.2018',
      showWatchlist: false,
      showSearch: true,
      accessField: "car_access",
      onClick: openCars,
      component: Autozalog,
    }, 
    [CHECKS]: {
      title: 'Проверки',
      id: 4,
      enabled: false,
      description: 'Модуль с данными по проведенным проверкам',
      dateCurrentData: '01.01.2017 по 01.01.2019',
      showWatchlist: false,
      showSearch: true,
      accessField: "check_access",
      onClick: openChecks,
      component: Checks,
    },
    [REALTY]: {
      title: 'Недвижимость',
      id: 5,
      enabled: false,
      description: 'Модуль с данными по объектам недвижимости',
      dateCurrentData: '01.01.2007 по 01.01.2018',
      showWatchlist: false,
      showSearch: true,
      accessField: "realty_access",
      onClick: openRealty,
      component: Realty
    }, 
    [TRANSPORT]: {
      title: 'Транспорт',
      id: 6,
      enabled: false,
      description: 'Модуль с данными по транспортным средствам',
      dateCurrentData: '01.01.2007 по 01.01.2018',
      showWatchlist: false,
      showSearch: true,
      accessField: "transport_access",
      onClick: openTransport,
      component: Transport
    },
  [BOT]: {
    title: 'Бот клиент',
    id: 7,
    enabled: false,
    description: 'Модуль для работы ботом',
    dateCurrentData: '01.01.2007 по 01.01.2018',
    showWatchlist: false,
    showSearch: true,
    accessField: "bot_access",
    onClick: openBotClient,
    component: BotClient
  },
  };




  export const cabinetKeys = [
      'sbs_access',
      'rfpi_access',
      'car_access',
      'amber_access',
      'realty_access',
      'transport_access',
      'bot_access'
  ];

 