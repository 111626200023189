import {Types} from "./action_types";

const showRequests = (id) => ({type: Types.GET_RFPI_REQUESTS, id});






function showRFPIDetailForm(id) {
    return {
        type: Types.GET_RFPI_DETAIL,
        id
    }
}

function sendSearchRequest(searchParams, page){

    return {
        type: Types.RFPI_SEARCH,
        searchParams,
        page,
    }
}

function closeRealtyDetail(){
    return {
        type: Types.CLOSE_RFPI_DETAIL,
    }
}

const addInStorgeList = (id) => ({type: Types.ADD_IN_RFPI_STORGE_LIST, id});
const deleteFromStorgeList = (id) => ({type: Types.DELETE_FROM_RFPI_STORGE_LIST, id});

export const rfpiActions = {
    closeDetail: closeRealtyDetail,
    showDetail: showRFPIDetailForm,
    search: sendSearchRequest,
    showRequests: showRequests,
    addInStorgeList,
    deleteFromStorgeList,
}