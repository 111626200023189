import {call, put, takeEvery} from "redux-saga/effects";
import {CHECK_CODE_SUCCESS} from "../auth_action_types";
import {cabTypes} from "./cabinet_action_types";
import {cabinetAPI} from "./cabinet_api";
import {OPEN_USER_CABINET} from "../index/index_action_types";
import {getStorageId} from "../../../helpers/storgelist_handlers";
import {SHOW_SBS_DETAIL} from "../../../cabinets/SBS/services/sbs_action_types";
import {Types as tTypes} from "../../../cabinets/Transport/services/transport_action_types";
import {Types as rfpiTypes} from "../../../cabinets/RFPI/service/action_types";

function* getUserInfoSaga(action) {
    yield put({
        type: cabTypes.GET_USER_INFO_START,
    })

    try {
        const res = yield call(cabinetAPI.getUserInfo);
        yield put({
            type: cabTypes.GET_USER_INFO_SUCCESS,
            userInfo: res.data
        })
        yield put({
            type: OPEN_USER_CABINET,
            userInfo: res.data
        })
    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения данных пользователя. ' + message
        });
        yield put({
            type: cabTypes.GET_USER_INFO_ERROR,
            error: error
        })
    }

}

function* approveUserInfoSaga(action) {

    try {
        yield call(cabinetAPI.approveUserData, action.delay);
        if (action.delay === false){

            yield put({
                type: cabTypes.APPROVE_USER_INFO_SUCCESS,
            })
        } else {
            yield put({
                type: cabTypes.DELAY_APPROVED,
            })
        }

    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.APPROVE_USER_INFO_ERROR,
            error: error
        })
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка отправки подтверждения. ' + message
        });
    }

}

function* sendSupportSaga(action) {

    try {
        yield call(cabinetAPI.sendToSupport, action.subject, action.message);
        yield put({
            type: cabTypes.SEND_MESSAGE_SUCCESS,
        })
        yield put({
            type: cabTypes.SHOW_POPUP_WINDOW,
            message: 'Запрос успешно отправлен'
        });

    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка отправки запроса. ' + message
        });
        yield put({
            type: cabTypes.SEND_MESSAGE_ERROR,
            error: error
        })
    }

}

function* findUserInfoSaga(action) {
    yield put({
        type: cabTypes.GET_USER_INFO_START,
    })

    try {
        const res = yield call(cabinetAPI.findUserInfo, action.searchParams);
        yield put({
            type: cabTypes.FIND_USER_INFO_SUCCESS,
            foundUsers: res.data
        })

    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + message
        });
        yield put({
            type: cabTypes.FIND_USER_INFO_ERROR,
            error: error
        })
    }

}

function* getStorgeList(action) {
    yield put({
        type: cabTypes.GET_USER_INFO_START,
    })

    try {
        const res = yield call(cabinetAPI.getStorgeData);
        const ids = getStorageId(res.data)

        yield put({
            type: cabTypes.GET_STORGE_LIST_SUCCESS,
            storgeList: res.data,
            sbsIDs: ids.sbs,
            rfpiIDs: ids.rfpi,
            transportIDS: ids.transport,
        })

    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения сторжевого листа. ' + message
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST_ERROR,
            error: error
        })
    }

}

function* openStorgeSBSDetail(action) {
    yield put ({
        type: SHOW_SBS_DETAIL,
            id: action.id
    })
    yield put ({
        type: cabTypes.OPEN_SBS,
    })
}

function* openStorgeRFPIDetail(action) {
    yield put ({
        type: rfpiTypes.GET_RFPI_DETAIL,
        id: action.id
    })
    yield put ({
        type: cabTypes.OPEN_RFPI,
    })
}

function* openStorgeTransportDetail(action) {
    yield put ({
        type: tTypes.SEND_TRANSPORT_REQUEST_DETAIL,
        id: action.id
    })
    yield put ({
        type: cabTypes.OPEN_TRANSPORT,
    })
}



export function* cabinetSagas() {
    yield takeEvery(CHECK_CODE_SUCCESS, getUserInfoSaga);
    yield takeEvery(cabTypes.GET_STORGE_LIST, getStorgeList);
    yield takeEvery(cabTypes.APPROVE_USER_INFO, approveUserInfoSaga);
    yield takeEvery(cabTypes.SEND_SUPPORT_MESSAGE, sendSupportSaga);
    yield takeEvery(cabTypes.FIND_USER_INFO, findUserInfoSaga);
    yield takeEvery(cabTypes.STORGE_SBS_CLICK, openStorgeSBSDetail);
    yield takeEvery(cabTypes.STORGE_RFPI_CLICK, openStorgeRFPIDetail);
    yield takeEvery(cabTypes.STORGE_TRANSPORT_CLICK, openStorgeTransportDetail);
}