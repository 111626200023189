export const Types = {
    RFPI_SEARCH: 'RFPI_SEARCH',
    RFPI_SEARCH_SUCCESS: 'RFPI_SEARCH_SUCCESS',
    RFPI_SEARCH_START: 'RFPI_SEARCH_START',
    RFPI_SEARCH_ERROR: 'RFPI_SEARCH_ERROR',

    GET_RFPI_DETAIL: 'GET_RFPI_DETAIL',
    SHOW_RFPI_DETAIL: 'SHOW_RFPI_DETAIL',
    GET_RFPI_DETAIL_START: 'GET_RFPI_DETAIL_START',
    GET_RFPI_DETAIL_ERROR: 'GET_RFPI_DETAIL_ERROR',
    CLOSE_RFPI_DETAIL: 'CLOSE_RFPI_DETAIL',

    GET_RFPI_REQUESTS: 'GET_RFPI_REQUESTS',
    SHOW_RFPI_REQUESTS: 'SHOW_RFPI_REQUESTS',
    GET_RFPI_REQUESTS_START: 'GET_RFPI_REQUESTS_START',
    GET_RFPI_REQUESTS_ERROR: 'GET_RFPI_REQUESTS_ERROR',

    ADD_IN_RFPI_STORGE_LIST: 'ADD_IN_RFPI_STORGE_LIST',
    ADD_IN_RFPI_STORGE_LIST_SUCCESS: 'ADD_IN_RFPI_STORGE_LIST_SUCCESS',
    ADD_IN_RFPI_STORGE_LIST_ERROR: 'ADD_IN_RFPI_STORGE_LIST_ERROR',
    DELETE_FROM_RFPI_STORGE_LIST: 'DELETE_FROM_RFPI_STORGE_LIST',
    DELETE_FROM_RFPI_STORGE_LIST_SUCCESS: 'DELETE_FROM_RFPI_STORGE_LIST_SUCCESS',
    DELETE_FROM_RFPI_STORGE_LIST_ERROR: 'DELETE_FROM_RFPI_STORGE_LIST_ERROR',

}