import React from "react";
import {connect} from "react-redux";
import "../../ui/Form.css";
import { SearchResults } from "../../ui/SearchResults/SearchResults";
import { SEARCH_MODES } from "./constants"
import {SearchPanel} from "../../elements/search_panel"
import SearchItem from "./searchItem"
import {realtyActions} from "./services/realty_actions";
import {isDebug} from "../../../constants/config";

class RealtySearchForm extends React.Component {

    state = {
        searchParams: {type: Object.values(SEARCH_MODES)[0].searchLabel},
        selectedMode: Object.keys(SEARCH_MODES)[0],
        update: this.props.update,
    }

    handleInputChange = (event) => {
        event.preventDefault();
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let sp = this.state.searchParams
        sp[name] = value
        this.setState({
            searchParams: sp
        });
        if (isDebug) {
            console.log(sp)

        }
    }

    setSelectedMode = (mode) => {
        this.setState({
            searchParams: {
                type: SEARCH_MODES[mode].searchLabel,
            },
            selectedMode: mode,

        });
    }

    startSearch = () => {
        this.props.search(this.state.searchParams)
    }


    render() {
        return (
            <div>
                <SearchPanel
                    onSubmit={this.startSearch}
                    onChange={this.handleInputChange}
                    selectedMode={this.state.selectedMode}
                    setSelectedMode={this.setSelectedMode}
                    searchModes={SEARCH_MODES}
                />
                {this.props.loading &&
                    <div>
                        <h2>Идет поиск....</h2>
                    </div>
                }
                {(!!this.props.foundRealties && !this.props.loading) &&
                <SearchResults title="Результаты поиска" count={"Найдено: " + this.props.foundRealties.length}>
                    {this.props.foundRealties.map((item, idx) => (
                        <SearchItem
                            key={`item-${idx}`}
                            object={item}
                            onClick={this.props.showDetail}
                            />

                    ))}
                </SearchResults>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        sendRequest: state.realty.sendRequest,
        loading: state.realty.loading,
        foundRealties: state.realty.foundRealties,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showDetail: id => dispatch(realtyActions.showDetail(id)),
        search: searchParams => dispatch(realtyActions.search(searchParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RealtySearchForm);