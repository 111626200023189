import {Types} from "./action_types";

function getClient() {
    return {
        type: Types.GET_CLIENT,
    }
}

function initClientDone() {
    return {
        type: Types.CLIENT_IS_INITIALIZED,
    }
}

function closeClient(uuid) {
    return {
        type: Types.CLOSE_CLIENT,
        uuid
    }
}

function showSubMenu() {
    return {
        type: Types.SHOW_SUB_MENU,
    }
}

function receiveMessage(message) {
    return {
        type: Types.RECEIVE_MESSAGE,
        message
    }
}

const showMainMenu = () => ({type: Types.SHOW_MAIN_MENU})
const waitMessage = () => ({type: Types.WAIT_MESSAGE})

export const botActions = {
    getClient,
    initClientDone,
    closeClient,
    showSubMenu,
    receiveMessage,
    showMainMenu,
    waitMessage,
}