export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';
export const ASK_CODE_START = 'ASK_CODE_START';
export const ASK_CODE_SUCCESS = 'ASK_CODE_SUCCESS';
export const ASK_CODE_ERROR = 'ASK_CODE_ERROR';
export const CHECK_CODE_START = 'CHECK_CODE_START';
export const CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS';
export const CHECK_CODE_ERROR = 'CHECK_CODE_ERROR';
export const CHECK_CODE_RETRY = 'CHECK_CODE_RETRY';
export const LOGOUT = 'LOGOUT';
