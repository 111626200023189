import React from 'react';
import {connect} from "react-redux";

import RealtySearchForm from './realty_search';
import {RealtyDetails} from './realty_detail'
import {realtyActions} from "./services/realty_actions";


class Realty extends React.Component {
    renderSearchTab = () => {
        if (this.props.showRealtyDetail) {
            //TODO передать статус объекта
            return <RealtyDetails
                onClose={this.props.closeDetail}
                realtyObjectData={this.props.realtyObjectData}
                addInStorgeList={this.props.addInStorgeList}
                deleteFromStorgeList={this.props.deleteFromStorgeList}
                storgeList={this.props.storgeList}
            />;
        } else {
            return <RealtySearchForm />;
        }
    }


    render() {
        return (this.renderSearchTab())
    }
}

function mapStateToProps(state) {
    return {
        showRealtyDetail: state.realty.showRealtyDetail,
        realtyObjectData: state.realty.realtyObjectData,
        foundRealties: state.realty.foundRealties,
        sendRequest: state.realty.sendRequest,
        updateSearch: state.realty.updateSearch,
        storgeList: state.realty.storgeList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(realtyActions.closeDetail()),
        addInStorgeList: (id) => dispatch(realtyActions.addInStorgeList(id)),
        deleteFromStorgeList: (id) => dispatch(realtyActions.deleteFromStorgeList(id)),
        //TODO getPDF()
        //TODO 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Realty);