export const SEARCH_PAGE_LOAD = 'SEARCH_PAGE_LOAD'
export const LOAD_CABINET = 'SEARCH_PAGE_SUCCESS'
export const LOAD_CABINET_ERROR = 'LOAD_CABINET_ERROR'
export const SYSTEM_MESSAGES_START = 'SYSTEM_MESSAGES_START'
export const SYSTEM_MESSAGES_SUCCESS = 'SYSTEM_MESSAGES_SUCCESS'
export const SYSTEM_MESSAGES_ERROR = 'SYSTEM_MESSAGES_ERROR'
export const SET_ACTIVE_CABINET = 'SET_ACTIVE_CABINET'
export const SHOW_USER_PROFILE = 'SHOW_USER_PROFILE'
export const SHOW_SEND_MESSAGE_SUPPORT = 'SHOW_SEND_MESSAGE_SUPPORT'
export const SHOW_STORAGE_LIST = 'SHOW_STORAGE_LIST'
export const SHOW_SEARCH = 'SHOW_SEARCH'
export const SHOW_USER_REFERENCE = 'SHOW_USER_REFERENCE'
export const SHOW_DB_UPDATE = 'SHOW_DB_UPDATE'
