import axios from "axios";
import qs from 'qs';
import {getAxios, postAxios, postAxiosAPINew} from "../../helpers/axios_helpers";
import { getCookie } from 'react-use-cookie';

export const authAPI = {
    getToken,
    getCode,
    checkCode,
}

async function getToken(data) {
    console.log('authApi get Token token before: ', getCookie('token'))
    const resp = await axios({
        ...postAxiosAPINew,
        url: postAxiosAPINew.url + 'obtain-auth-token/',
        headers: {Accept: 'application/json'},
        data: qs.stringify(data),
    })
    return resp
}

async function getCode() {
    const resp =  await axios({
        ...getAxios,
        url: getAxios.url + 'send_sms/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    return resp
}

async function checkCode(code) {
    const resp = await axios({
        ...postAxios,
        url: postAxios.url + 'send_sms/',
        data: qs.stringify({ 'code': code }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    return resp
}

