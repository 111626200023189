import { put, call } from 'redux-saga/effects';
import { setMoreInfoUser } from '../actions/moreInfoUser';
import axios from 'axios';
import { getCookie } from 'react-use-cookie';

function* getMoreInfo({ids}) {
  try {
    const res = yield call(axios,{
      method: 'GET',
      url: '/api-new/entity/' + ids.entity,
      headers: {
        'Authorization': 'Token ' + getCookie('token')
      }
    });
    const resProfil = yield call(axios, {
      method: 'GET',
      url: '/api-new/users/' + ids.users,
      headers: {
        'Authorization': 'Token ' + getCookie('token')
      }
    });
    yield put(setMoreInfoUser({
      companyName: res.data.name,
      img: res.data.logo,
      phone: resProfil.data.phone,
      middle_name: resProfil.data.middle_name,
      first_name: resProfil.data.first_name,
      last_name: resProfil.data.last_name,
      showModal: true
    }));
  } catch(err) {
    console.log(err);
  }
}

export {
  getMoreInfo
}
