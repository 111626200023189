export const SEARCH_MODES =  {
    'KADASTR': {
        searchLabel: 'Кадастровый номер',
        fields:[{
            name: 'kadastr_number',
            placeholder: 'Кадастровый номер',
            title: 'Кадастровый номер',
            typeInput: 'text',
            required: true,
            value: '',
        }],
    },
    'FIO': {
        searchLabel: 'ФИО собственника',
        fields: [{
            name: 'last_name',
            placeholder: 'Фамилия',
            title: 'Фамилия',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'first_name',
            placeholder: 'Имя',
            title: 'Имя',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'middle_name',
            placeholder: 'Отчество',
            title: 'Отчество',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'birth_date',
            placeholder: '01.01.2000',
            title: 'Дата рождения',
            typeInput: 'date',
            required: true,
            value: '',
        }
        ]
    }, 
    'INN':{
        searchLabel: 'ИНН',
        fields:[{
            name: 'inn',
            placeholder: 'ИНН',
            title: 'ИНН',
            typeInput: 'text',
            required: true,
            value: '',
        }],
        masked: '12'
    },
    'ADDRESS': {
        searchLabel: 'Адрес объекта',
        fields: [{
            name: 'city',
            placeholder: 'Город',
            title: 'Город',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'street',
            placeholder: 'Улица',
            title: 'Улица',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'building',
            placeholder: 'Номер дома',
            title: 'Номер дома',
            typeInput: 'text',
            required: false,
            value: '',
        },{
            name: 'flat',
            placeholder: 'Номер квартиры',
            title: 'Номер квартиры',
            typeInput: 'text',
            required: false,
            value: '',
        }
        ]
    }
}