import React from "react";
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import {Requests} from "./requests";
import {Comments} from "./comments";
import {autozalogActions} from "./services/actions";
import {connect} from "react-redux";



function convertOutputDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}


/**
 * @typedef SbsDetailsPropsType
 * @property {JSX.Element | string} children
 */

/**
 *
 * @param {SbsDetailsPropsType} props
 * @returns {JSX.Element}
 */

class AutozalogDetails extends React.Component{
    // const inStorageList = isInStorgeList(ob.id, props.storgeList)
    // console.log('inStorageList: ', inStorageList)
    render() {
        const ob = this.props.objectData

    return (
        <div className="BoxesList">
            <ResultBox
                headerItems={
                    <React.Fragment>
                        {/*<Button*/}
                        {/*    additionalCssClass="_addToList"*/}
                        {/*    onClick={!inStorageList*/}
                        {/*        ? ()=> props.addInStorgeList(ob.id)*/}
                        {/*        : () => props.deleteFromStorgeList(ob.id)}>*/}
                        {/*    {!inStorageList ? 'Добавить в сторожевой лист' : 'Удалить из сторжевого листа'}*/}
                        {/*</Button>*/}
                        <Button className="Close-button" onClick={this.props.onClose}>
                            <span aria-hidden="true">&times;</span>
                        </Button>
                    </React.Fragment>
                }
            >
                <FieldValueText field="Фамилия: " value={ob.last_name} />
                <FieldValueText field="Имя: " value={ob.first_name} />
                <FieldValueText field="Отчество: " value={ob.middle_name} />
                <FieldValueText field="Дата рождения: " value={convertOutputDate(ob.birth_date)} />
                <FieldValueText field="Город: " value={ob.city} />
                <FieldValueText field="Адрес: " value={`${ob.street} ${ob.house}`} />
                <FieldValueText field="VIN: " value={ob.vin} />
                <FieldValueText field="Марка: " value={ob.brand_name} />
                <FieldValueText field="Модель: " value={ob.vehicle_model.name} />
                <FieldValueText field="Номер кузова: " value={ob.body_number} />
                <FieldValueText field="Цвет: " value={ob.color} />
                <FieldValueText field="Номер: " value={ob.register_number} />
                <FieldValueText field="Год производства: " value={ob.production_year} />
                <FieldValueText field="Цвет: " value={ob.color} />

            </ResultBox>
            <ResultBox
                expandTitle="Показать запросы"
                collapseTitle="Скрыть запросы"
                onClick={() => (this.props.showRequests(ob.id))}
                notExpanded={true}
            >
                {this.props.waitRequests ? <h3>Загрузка запросов....</h3> :
                    <Requests
                        requests={this.props.requests}
                    />}
            </ResultBox>
            <ResultBox
                expandTitle="Показать комментарии"
                collapseTitle="Скрыть комментарии"
                onClick={() => (this.props.showComments(ob.id))}
                notExpanded={true}
            >
                {this.props.waitComments ? <h3>Загрузка комментариев....</h3> :
                    <Comments
                        comments={this.props.comments}
                        addComment={this.props.addComment}
                        entityID={ob.id}
                    />}
            </ResultBox>

        </div>
    );
    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.autozalog.showDetail,
        ObjectData: state.autozalog.ObjectData,
        sendRequest: state.autozalog.sendRequest,
        waitRequests: state.autozalog.waitRequests,
        waitComments: state.autozalog.waitComments,
        requests: state.autozalog.requests,
        comments: state.autozalog.comments,

        loading: state.autozalog.loading,
        // storgeList: state.autozalog.storgeList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(autozalogActions.closeDetail()),
        showComments: (id) => dispatch(autozalogActions.showComments(id)),
        showRequests: (id) => dispatch(autozalogActions.showRequests(id)),
        addComment: (comment, id) => dispatch(autozalogActions.addComment(comment, id)),
        //TODO getPDF()
        //TODO
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutozalogDetails);
