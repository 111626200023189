import React from "react";
import "./InfoBox.module.css";

/**
 * @typedef InfoItemType
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {string} date
 * @property {boolean} hasAccess
 */

/**
 * @typedef PropType
 * @property {InfoItemType} item
 */

/**
 *
 * @param {PropType} props
 * @returns {JSX.Element}
 */

export const InfoBox = (props) => {
  return (
    <div key={`item-${props.item.id}`} className="InfoBox" onClick={()=>props.item.enabled ? props.onClick(props.item.onClick) : ()=>{} }>
      <h3 className="InfoBoxTitle">{props.item.title}</h3>
      <div className="InfoBoxDescription">{props.item.description}</div>
      <div className="InfoBoxFooter">
        <div className={`InfoBoxAccess${props.item.enabled ? " _green" : ""}`}>
          <span
            className={`InfoBoxAccessCircle ${props.item.enabled ? " _green" : ""}`}
          />
          {props.item.enabled ? "Доступен" : "Недоступен"}
        </div>
        <div className="InfoBoxDate">{props.item.dateCurrentData}</div>
      </div>
    </div>
  );
};
