import React, {useCallback, useState} from "react";
import {Button} from "../../ui/Button/Button";
import {formatDate} from "../../helpers/handleDate";

export const Comments = (props) => {
    const [comment, setComment] = useState('');
    const onCommentChange = useCallback(
        (e) => {
            setComment(e.target.value);
        },
        [comment, setComment]
    );

    const onSubmit = (e) => {
        const id = props.entityID;
        e.preventDefault();
        setComment('');
        props.addComment(comment, id);
    }

    //TODO add pagination
    return (
        <React.Fragment>
            <div>
            <form className="Form" onSubmit={onSubmit}>
                <div className="FormField">
                    <fieldset className="FormFieldSet _wide">
                     <textarea className="FormTextarea"
                               placeholder="Ваш запрос" onChange={onCommentChange} value={comment}/>
                    </fieldset>

                    <div className="FormField">
                        <Button type="submit" additionalCssClass="_addToList">Добавить</Button>
                    </div>
                </div>
            </form>
            </div>
            <table className="RequestsTable" cellPadding="0" cellSpacing="0">
                <thead>
                <tr>
                    <th>Автор</th>
                    <th>Комментарий</th>
                </tr>
                </thead>
                <tbody>
                {!!props.comments && props.comments.results.map((item, idx) => (
                    <tr key={`item-${idx}`}>
                        <td>
                            <h6>{item.user}</h6>
                            <small>{formatDate(item.date)}</small><br />
                            <small>{item.entity}</small>
                        </td>
                        <td>
                            <p>{item.text}</p>
                        </td>
                    </tr>
                ))}
                </tbody>


            </table>
        </React.Fragment>
    )
}