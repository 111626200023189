import {isDebug} from "../../constants/config";
import { getCookie } from 'react-use-cookie';


export const getAxios = {
    method: 'GET',
    url: (isDebug ? 'http://localhost:8000/' : '/'),
    headers: {
        Accept: 'application/json',
        'Authorization': 'Token ' + getCookie('token')
    },
}

export const postAxios = {
    method: 'POST',
    url: (isDebug ? 'http://localhost:8000/' : '/'),
    headers: {
        Accept: 'application/json',
        'Authorization': 'Token ' + getCookie('token')
    },
    data: null
}



export const getAxiosAPI = {
    method: 'GET',
    url: (isDebug ? 'http://localhost:8000/api/' : '/api/'),
    headers: {
        Accept: 'application/json',
        'Authorization': 'Token ' + getCookie('token')
    },
}

export const postAxiosAPI = {
    method: 'POST',
    url: (isDebug ? 'http://localhost:8000/api/' : '/api/'),
    headers: {
        Accept: 'application/json',
        'Authorization': 'Token ' + getCookie('token')
    },
    data: null
}

export const deleteAxiosAPI = {
    method: 'DELETE',
    url: (isDebug ? 'http://localhost:8000/api/' : '/api/'),
    headers: {
        Accept: 'application/json',
        'Authorization': 'Token ' + getCookie('token')
    },
    data: null
}


export const getAxiosAPINew = {
    method: 'GET',
    url: (isDebug ? 'http://localhost:8000/api-new/' : '/api-new/'),

}

export const postAxiosAPINew = {
    method: 'POST',
    url: (isDebug ? 'http://localhost:8000/api-new/' : '/api-new/'),
    headers: {
        Accept: 'application/json',
        'Authorization': 'Token ' + getCookie('token')
    },
    data: null
}

