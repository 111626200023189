import React from "react";
import {formatDate} from "../../helpers/handleDate";


export const Requests = (props) => {
    return(
        <React.Fragment>
            <table className="RequestsTable" cellPadding="0" cellSpacing="0">
                <thead>
                <tr>
                    <th>Дата</th>
                    <th>ФИО</th>
                    <th>Организация</th>
                </tr>
                </thead>
                <tbody>
                {!!props.requests && props.requests.results.map((item, idx) => (
                    <tr key={`item-${idx}`}>
                        <td>{formatDate(item.date)}</td>
                        <td
                            data-toggle="tooltip"
                            data-placement="top"
                            title={item.user_phone + "\n" + item.user_email}>
                            {item.user_name}
                        </td>
                        <td>{item.user_entity}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </React.Fragment>
    )
}