import React, {useEffect, useState} from "react";
import {Button} from "../../ui/Button/Button";
import {useSelector} from "react-redux";
import {MainTitle} from "../../ui/Typography/Typography";
import {Input} from "../../ui/Input/Input";


export const SubMenu = (props) => {
    const messages = useSelector(state => state.bot.messageData);

    const [text, setText] = useState('')

    const [showRequestInput, setShowRequestInput] = useState(true)

    const handleInputChange = (event) => {
        event.preventDefault();
        const target = event.target;
        setText(target.value)
    }

    useEffect( () => {
        if (!messages) {
            return
        }
        messages.forEach((message) => {
            if (message.buttons.length > 0) {
                setShowRequestInput(false)
            }
        })
    },[messages])

    const Image = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} />

    console.log('message: ', messages)

    return (
        <div>
            {messages &&
                <React.Fragment>
                    {messages.map((message) => (
                    <React.Fragment>
                        {message.media && <Image data={message.media}/>}
                    {message.text && message.text.split('\n').map((item, idx) => (
                    <div className="InfoBoxDescription" key={`text-${idx}`}>{item}</div>
                    ))
                    }
                    {message.caption && message.caption.split('\n').map((item, idx) => (
                        <div className="InfoBoxDescription" key={`item-${idx}`}>{item}</div>
                        ))
                    }
                    {message.buttons.length > 0 &&
                        <fieldset className="FormFieldSet">
                            {message.buttons.map((item, idx) => (
                                <Button type="button"
                                        key={`button-${idx}`}
                                        additionalCssClass="_botMenu"
                                        onClick={()=>props.clickButton(item.text, message.msg_id)}>
                                    {item.text}
                                </Button>
                                )
                            )
                        }
                        </fieldset>
                    }
                    </React.Fragment>
                    ))}
                    {showRequestInput &&
                        <div className="Form" onSubmit={()=>{}}>
                            <MainTitle>Введите запрос</MainTitle>
                            <fieldset className="FormFieldSet">

                                <Input
                                    placeholder={"Запрос"}
                                    required={false}
                                    type={"text"}
                                    onChange={handleInputChange}
                                    name={"text"}
                                    // value={inputs[typeSearch].value}
                                />
                            </fieldset>
                            <Button additionalCssClass="_botMenuSearch" type="button" onClick={()=>props.sendMessage({text, })}>Отправить запрос</Button>
                        </div>
                    }
                    <Button type="button"
                            additionalCssClass="_botMenuSearch"
                            onClick={()=>props.clickMainMenu("ℹ️ Показать меню")}>
                        Главное меню
                    </Button>
                </React.Fragment>

            }
        </div>

    )
}