export const SEARCH_MODES =  {
    'ABONENT': {
        searchLabel: 'Абонент',
        fields:[{
            name: 'abonent',
            placeholder: 'Абонент',
            title: 'Абонент',
            typeInput: 'text',
            required: true,
            value: '',
        }],
    },

    'PHONE_NUMBER':{
        searchLabel: 'Номер телефона',
        fields:[{
            name: 'phone_number',
            placeholder: 'Номер телефона',
            title: 'Номер телефона',
            typeInput: 'text',
            required: true,
            value: '',
        }],
    },

}