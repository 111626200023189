import React from "react";
import "../Form.css"

/**
 * @typedef PropType
 * @property {string} type
 * @property {string} value
 * @property {string} placeholder
 * @property {'big'} size?
 * @callback onChange
 */

/**
 *
 * @param {PropType} props
 * @returns {JSX.Element}
 */
export const Textarea = (props) => {
  return (
            <textarea
                className="FormTextarea"
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                name={props.name}
            />

  );
};
