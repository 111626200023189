import { combineReducers } from 'redux';
import { authReducer } from "../../components/pages/services/auth_reducer";
import { cabinetReducer } from "../../components/pages/services/cabinet/cabinet_reducer";
import { indexReducer } from "../../components/pages/services/index/index_reducer";
import { realtyReducer } from "../../components/cabinets/Realty/services/realty_reducers";
import { userProfileReducer } from "../../components/cabinets/userCabinet/services/user_profile_reducers";
import { sbsReducer } from "../../components/cabinets/SBS/services/sbs_reducers";
import { transportReducer } from "../../components/cabinets/Transport/services/transport_reducers";
import { rfpiReducer } from "../../components/cabinets/RFPI/service/redusers";
import {autozlogReducer} from "../../components/cabinets/Autozalog/services/reducers";
import {jantarReducer} from "../../components/cabinets/Jantar/services/reducers";
import {botClientReducer} from "../../components/cabinets/BotClient/services/reduser";
import {checksReducer} from "../../components/cabinets/Checks/services/reduser";

const rootReducer =  combineReducers({
  index: indexReducer,
  auth: authReducer,
  cabinet: cabinetReducer,
  userProfile: userProfileReducer,
  realty: realtyReducer,
  sbs: sbsReducer,
  transport: transportReducer,
  rfpi: rfpiReducer,
  autozalog: autozlogReducer,
  jantar: jantarReducer,
  checks: checksReducer,
  // moreInfoUser,
  bot: botClientReducer,
});

export default rootReducer;
