import React from "react";
import "./ChecksDetails.module.scss";
import { FieldValueText } from "../../ui/Typography/Typography";
import {ResultBox} from "../../ui/ResultBox/ResultBox";
import {Button} from "../../ui/Button/Button";
// import { Button } from "../../ui/Button/Button";
// import {isInStorgeList} from "../../helpers/storgelist_handlers";



function convertOutputDate(date) {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }
}


/**
 * @typedef SbsDetailsPropsType
 * @property {JSX.Element | string} children
 */

/**
 *
 * @param {SbsDetailsPropsType} props
 * @returns {JSX.Element}
 */

export const ChecksDetails = (props) => {
    const ob = props.objectData
    // const inStorageList = isInStorgeList(ob.id, props.storgeList)
    return (
        <div className="BoxesList">
            <ResultBox
                headerItems={
                    <React.Fragment>
                        {/*<Button*/}
                        {/*    additionalCssClass="_addToList"*/}
                        {/*    onClick={!inStorageList*/}
                        {/*        ? ()=> props.addInStorgeList(ob.id)*/}
                        {/*        : () => props.deleteFromStorgeList(ob.id)}>*/}
                        {/*    {!inStorageList ? 'Добавить в сторожевой лист' : 'Удалить из сторжевого листа'}*/}
                        {/*</Button>*/}
                        <Button className="Close-button" onClick={props.onClose}>
                            <span aria-hidden="true">&times;</span>
                        </Button>
                    </React.Fragment>
                }
            >
                <FieldValueText field="Дата проверки: " value={convertOutputDate(ob.date_check)} />
                <FieldValueText  field="ФИО проверяемого: " value={ob.person.full_name}/>
                <FieldValueText  field="Дата рождения: " value={convertOutputDate(ob.person.birth_date)} />
                <FieldValueText  field="Дата смерти: " value={convertOutputDate(ob.person.death_date)} />
                <FieldValueText  field="Паспорт серия  и номер:" value={ob.id_card ? ob.id_card.number : ''} />
                <FieldValueText  field="Дата выдачи:" value={ob.id_card ? convertOutputDate(ob.id_card.date_of_issue) : ''} />
                <FieldValueText  field="Кем выдан:" value={ob.id_card ? ob.id_card.issue_by : ''} />
                <FieldValueText  field="Адрес регистрации:" value={ob.registr_address ? ob.registr_address.address : ''} />
                <FieldValueText  field="Адрес места жительства:" value={ob.residence_address ? ob.residence_address.address : ''} />

                <FieldValueText field="Наличие профучета «наркоман»: " value={ob.junkie} />
                <FieldValueText field="Наличие профучета «алкоголик»: " value={ob.alcoholic} />
                <FieldValueText field="Наличие профучета «бомж»: " value={ob.bum} />
                <FieldValueText field="Наличие профучета «неблагополучный родитель»: " value={ob.bad_parent} />
                <FieldValueText field="Дебошир: " value={ob.rowdy} />
                <FieldValueText field="Судимость: " value={ob.criminal_record} />
                <FieldValueText field="Нарушения по нарк. статьям: " value={ob.offence_junk} />
                <FieldValueText field="Нарушения по алк. статьям: " value={ob.offence_alco} />
                <FieldValueText field="Уголовное преследование: " value={ob.offence_crim} />
                <FieldValueText field="Информация: " value={ob.info} />


            </ResultBox>

        </div>
    );
};
