import { Types } from "./realty_action_types";



export function showRealtyDetailForm(id) {
    return { type: Types.SHOW_REALTY_DETAIL_START, id }
}

export function sendRequest() {
    return {
        type: Types.SEND_REQUEST,
    }
}

export function sendSearchRequest(searchParams){
    return { type: Types.REALTY_SEARCH, searchParams }
}


export function closeRealtyDetail(){
    return { type: Types.CLOSE_REALTY_DETAIL, }
}

const addInStorgeList = (id) => ({type: Types.ADD_IN_REALTY_STORGE_LIST, id});
const deleteFromStorgeList = (id) => ({type: Types.DELETE_FROM_REALTY_STORGE_LIST, id});

export const realtyActions = {
    showDetail: showRealtyDetailForm,
    search: sendSearchRequest,
    closeDetail: closeRealtyDetail,
    addInStorgeList,
    deleteFromStorgeList,
}

