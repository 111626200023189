export const cabTypes = {

    GET_USER_INFO_START: 'GET_USER_INFO_START',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_ERROR: 'GET_USER_INFO_ERROR',

    SHOW_POPUP: 'SHOW_POPUP',
    CLOSE_POPUP: 'CLOSE_POPUP',
    SHOW_ERROR: 'SHOW_ERROR',
    CLOSE_ERROR: 'CLOSE_ERROR',

    OPEN_CABINET: 'OPEN_CABINET',
    OPEN_USER_REFERENCE: 'OPEN_USER_REFERENCE',
    OPEN_SUPPORT: 'OPEN_SUPPORT',
    OPEN_STORGE_LIST: 'OPEN_STORGE_LIST',
    OPEN_SBS: 'OPEN_SBS',
    OPEN_RFPI: 'OPEN_RFPI',
    OPEN_AMBER: 'OPEN_AMBER',
    OPEN_CARS: 'OPEN_CARS',
    OPEN_REALTY: 'OPEN_REALTY',
    OPEN_TRANSPORT: 'OPEN_TRANSPORT',
    OPEN_CHECKS: 'OPEN_CHECKS',
    OPEN_BOT_CLIENT: 'OPEN_BOT_CLIENT',
    LOGOUT: 'LOGOUT',

    APPROVED_USER_DATA: 'APPROVED_USER_DATA',
    DELAY_APPROVED: 'DELAY_APPROVED',

    SEND_SUPPORT_MESSAGE: 'SEND_SUPPORT_MESSAGE',
    SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR',

    FIND_USER_INFO: 'FIND_USER_INFO',
    FIND_USER_INFO_START: 'FIND_USER_INFO_START',
    FIND_USER_INFO_SUCCESS: 'FIND_USER_INFO_SUCCESS',
    FIND_USER_INFO_ERROR: 'FIND_USER_INFO_ERROR',

    APPROVE_USER_INFO: 'APPROVE_USER_INFO',
    APPROVE_USER_INFO_START: 'APPROVE_USER_INFO_START',
    APPROVE_USER_INFO_SUCCESS: 'APPROVE_USER_INFO_SUCCESS',
    APPROVE_USER_INFO_ERROR: 'APPROVE_USER_INFO_ERROR',

    GET_STORGE_LIST: 'GET_STORGE_LIST',
    GET_STORGE_LIST_SUCCESS: 'GET_STORGE_LIST_SUCCESS',
    GET_STORGE_LIST_ERROR: 'GET_STORGE_LIST_ERROR',

    STORGE_SBS_CLICK: 'STORGE_SBS_CLICK',
    STORGE_RFPI_CLICK: 'STORGE_RFPI_CLICK',
    STORGE_TRANSPORT_CLICK: 'STORGE_TRANSPORT_CLICK',

    SHOW_POPUP_WINDOW: 'SHOW_POPUP_WINDOW',
    CLOSE_POPUP_WINDOW: 'CLOSE_POPUP_WINDOW',

    SHOW_POPUP_ERROR: 'SHOW_POPUP_ERROR',
    CLOSE_POPUP_ERROR: 'CLOSE_POPUP_ERROR',
}