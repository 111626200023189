export const SBS_SEND_SEARCH_REQUEST = 'SBS_SEND_SEARCH_REQUEST'
export const SBS_SEARCH_START = 'SBS_SEARCH_START'
export const CLOSE_SBS_DETAIL = 'CLOSE_SBS_DETAIL'
export const SHOW_SBS_DETAIL = 'SHOW_SBS_DETAIL'
export const SHOW_SBS_DETAIL_START = 'SHOW_SBS_DETAIL_START'
export const SHOW_SBS_DETAIL_SUCCESS = 'SHOW_SBS_DETAIL_SUCCESS'
export const SHOW_SBS_DETAIL_ERROR = 'SHOW_SBS_DETAIL_ERROR'
export const SHOW_SBS_AMOUNTS = 'SHOW_SBS_AMOUNTS'
export const SHOW_SBS_AMOUNTS_START = 'SHOW_SBS_AMOUNTS_START'
export const SHOW_SBS_AMOUNTS_SUCCESS = 'SHOW_SBS_AMOUNTS_SUCCESS'
export const SHOW_SBS_AMOUNTS_ERROR = 'SHOW_SBS_AMOUNTS_ERROR'
export const SHOW_SBS_REQUESTS = 'SHOW_SBS_REQUESTS'
export const SHOW_SBS_REQUESTS_START = 'SHOW_SBS_REQUESTS_START'
export const SHOW_SBS_REQUESTS_SUCCESS = 'SHOW_SBS_REQUESTS_SUCCESS'
export const SHOW_SBS_REQUESTS_ERROR = 'SHOW_SBS_REQUESTS_ERROR'
export const SHOW_SBS_COMMENTS = 'SHOW_SBS_COMMENTS'
export const ADD_SBS_COMMENTS = 'ADD_SBS_COMMENTS'
export const ADD_SBS_COMMENT_SUCCESS = 'ADD_SBS_COMMENT_SUCCESS'
export const ADD_SBS_COMMENT_ERROR = 'ADD_SBS_COMMENT_ERROR'
export const SHOW_SBS_COMMENTS_START = 'SHOW_SBS_COMMENTS_START'
export const SHOW_SBS_COMMENTS_SUCCESS = 'SHOW_SBS_COMMENTS_SUCCESS'
export const SHOW_SBS_COMMENTS_ERROR = 'SHOW_SBS_COMMENTS_ERROR'
export const SBS_SEARCH_SUCCESS = 'SBS_SEARCH_SUCCESS'
export const SBS_SEARCH_ERROR = 'SBS_SEARCH_ERROR'
export const REQUEST_MADE_START = 'REQUEST_MADE_START'
export const REQUEST_MADE_SUCCESS = 'REQUEST_MADE_SUCCESS'
export const REQUEST_MADE_ERROR = 'REQUEST_MADE_ERROR'

export const ADD_IN_SBS_STORGE_LIST = 'ADD_IN_SBS_STORGE_LIST'
export const ADD_IN_SBS_STORGE_LIST_SUCCESS = 'ADD_IN_SBS_STORGE_LIST_SUCCESS'
export const ADD_IN_SBS_STORGE_LIST_ERROR = 'ADD_IN_SBS_STORGE_LIST_ERROR'
export const DELETE_FROM_SBS_STORGE_LIST = 'DELETE_FROM_SBS_STORGE_LIST'
export const DELETE_FROM_SBS_STORGE_LIST_SUCCESS= 'DELETE_FROM_SBS_STORGE_LIST_SUCCESS'
export const DELETE_FROM_SBS_STORGE_LIST_ERROR = 'DELETE_FROM_SBS_STORGE_LIST_ERROR'
