import {Types} from "./action_types";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";

const initialState = {
    showDetail: false,
    objectData: null,
    foundObjects: null,
    sendRequest: false,
    loading: false,
    error: null,
    searchParams: null,
    storgeList: null,
    updateSearch: false,
    page: 1,
}


const handlers = {

    [Types.RFPI_SEARCH_START]: (state, action) =>({...state, loading: true, error: null}),
    [Types.RFPI_SEARCH_SUCCESS]: (state, action)=>({...state, loading: false, foundObjects: action.foundObjects}),
    [Types.RFPI_SEARCH_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),

    [cabTypes.GET_STORGE_LIST_SUCCESS]: (state, action)=>({...state, storgeList: action.rfpiIDs}),


    DEFAULT: state => state
}

export function rfpiReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}