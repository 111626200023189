import React from 'react';
import { ResultBox } from "../../ui/ResultBox/ResultBox";
import { FieldValueText} from "../../ui/Typography/Typography";


const SearchItem = props => {
    return (
        <ResultBox onClick={()=>props.onClick(props.item.id)}>
                <FieldValueText field="Наименование организации: " value={`${props.item.entity_type} ${props.item.entity_name}`}/>
                <FieldValueText field="ИНН: " value={props.item.inn}/>
                <FieldValueText field={props.item.entity_type === 'ИП' ? 'ОГРНИП: ' : 'ОГРН: '} value={props.item.ogrn_ogrnip} />
                <FieldValueText field="Адрес: " value={props.item.address}/>
        </ResultBox>

       
    );
}



export default SearchItem;
