import React, {useCallback, useState} from "react";
import {Button} from "../../ui/Button/Button";
import {Input} from "../../ui/Input/Input";
import {MainTitle} from "../../ui/Typography/Typography";
import "./Bot.module.css";
import {isDebug} from "../../../constants/config";




export const MainMenu = (props) => {
    const [typeSearch, setTypeSearch] = useState('')

    const [text, setText] = useState('')

    const inputs = {
        'name': {
            name: 'name',
            placeholder: 'Иванов Иван Иванович 05.04,1984',
            title: 'Поиск по ФИО и дате рождения',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'auto': {
            name: 'auto',
            placeholder: 'гос.номер или VIN',
            title: 'Поиск по гос.номеру или VIN',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'tel': {
            name: 'tel',
            placeholder: '79999939919',
            title: 'Поиск по номеру телефона',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'soc_seti': {
            name: 'soc_seti',
            placeholder: 'https://vk.com/id1',
            title: 'Поиск по странице социальных сетей',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'email': {
            name: 'email',
            placeholder: 'tema@gmail.com',
            title: 'Поиск по электронной почте',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'inn': {
            name: 'inn',
            placeholder: '784806113663',
            title: 'Поиск организации по инн',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'company': {
            name: 'company',
            placeholder: 'Стальпром',
            title: 'Поиск организации по названию',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'kadastr': {
            name: 'kadastr',
            placeholder: '77:01:0001075:1361',
            title: 'Поиск по кадастрововму номеру',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        },
        'adr': {
            name: 'adr',
            placeholder: 'Москва, Тверская, д 1, кв 1 - информация по адресу (РФ)',
            title: 'Поиск по адресу',
            typeInput: 'text',
            required: true,
            value: text,
            onChange: setText,
        }
    }

    const handleInputChange = (event) => {
        event.preventDefault();
        const target = event.target;
        // const value = target.value;
        const name = target.name;
        switch (name) {
            case 'inn':
                setText('/inn ' + target.value);
                break
            case 'company':
                setText('/company ' + target.value);
                break
            case 'adr':
                setText('/adr ' + target.value);
                break
            default:
                setText(target.value);
                break
        }
    }


    const SearchTypes = () => {
        return (
            <div className="MainMenu">
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('name')} >поиск по ФИО и д.р.</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('auto')} >поиск по Автомобилю</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('tel')} >поиск по Номеру телефона</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('email')} >поиск по Email</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('soc_seti')} >поиск по Соц сетям</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('adr')} >поиск по Адресу</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('inn')} >поиск по ИНН</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('company')} >поиск по Названию компании</Button>
                <Button type="submit" additionalCssClass="_botMenu" onClick={()=>setTypeSearch('kadastr')} >поиск по Кадастровому номеру</Button>
            </div>
        )
    }

    return (
        <div>

            {!typeSearch &&
                <React.Fragment>
                    <MainTitle>Выберите раздел:</MainTitle>
                    <SearchTypes />
                </React.Fragment>
                }
            {!!typeSearch &&
                <form className="Form" onSubmit={()=>{}}>
                    <MainTitle>{inputs[typeSearch].title}</MainTitle>
                <fieldset className="FormFieldSet">

                    <Input
                        placeholder={inputs[typeSearch].placeholder}
                        required={inputs[typeSearch].required}
                        type={inputs[typeSearch].type}
                        onChange={handleInputChange}
                        name={inputs[typeSearch].name}
                        // value={inputs[typeSearch].value}
                    />
                </fieldset>
                <Button additionalCssClass="_botMenuSearch" type="button" onClick={()=>props.sendMessage({typeSearch, text})}>поиск</Button>
                </form>
            }
        </div>
    )
}