import {
    GET_USER_INFO_SUCCESS, SEARCH_PAGE_LOAD,
    LOAD_CABINET, SHOW_DB_UPDATE,
    SYSTEM_MESSAGES_ERROR,
    SYSTEM_MESSAGES_START, SYSTEM_MESSAGES_SUCCESS
} from "./user_profile_action_types";

const initialState = {
    //SearchPage
    userInfo: null,
    messages: null,
    userReferenceAccess: false,
    watchListData: null,

    loding: false,
    pageLoading: true,
    error: null
}

const handlers = {
    [SEARCH_PAGE_LOAD]: state =>({...state, pageLoading: true, error: null}),
    [SYSTEM_MESSAGES_START]: state =>({...state, error: null}),
    [SYSTEM_MESSAGES_SUCCESS]: (state, action)=>({...state, messages: action.messages, showPopup: true}),
    [SYSTEM_MESSAGES_ERROR]: (state, action)=>({...state, error: action.error}),
    [LOAD_CABINET]: (state, action)=>({
        ...state,
        loading: false,
        userInfo: action.userInfo,
    }),
    [SHOW_DB_UPDATE]: (state, action)=>({...state, tab: action.tab, sendSupport: false}),
    DEFAULT: state => state
}

export function userProfileReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}