import React, {useState, useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket'

import "./Bot.module.css";
import {botActions} from "./services/action";
import {MainMenu} from "./main_menu";
import {Button} from "../../ui/Button/Button";
import {SubMenu} from "./sub_menu";
import {isDebug} from "../../../constants/config";


const BotClient = () => {

    //Подтягиваем значение из state redux
    const botSessionUUID = useSelector(state => state.bot.botSessionUUID);
    const showMainMenu = useSelector(state => state.bot.showMainMenu);
    const showSubMenu = useSelector(state => state.bot.showSubMenu);
    const loading = useSelector(state => state.bot.loading);
    const dispatch = useDispatch();

    // console.log("loading: ", loading)
    // console.log("showMainMenu: ", showMainMenu)
    // console.log("showSubMenu: ", showSubMenu)

    const baseURL = (isDebug ? 'ws://localhost:8000/bot_channel/' : 'wss://delfin.online/bot_channel/')

    const [socketUrl, setSocketUrl] = useState(baseURL);
    const [startSession, setStartSession] = useState(false);
    const [messageHistory, setMessageHistory] = useState([]);
    const [imageData, setImageData] = useState('');

    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl);



    useEffect(() => {
        if (botSessionUUID) {
            setSocketUrl(baseURL + botSessionUUID);
        }

    }, [botSessionUUID])

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev) => prev.concat(lastMessage));
            console.log("lastMessage: ", lastMessage)
            console.log("lastMessage.data: ", JSON.parse(lastMessage.data));
            dispatch(botActions.receiveMessage(JSON.parse(lastMessage.data)))
        }

    }, [lastMessage, setMessageHistory]);

    useEffect(()=>{
        if (readyState === ReadyState.OPEN) {
            setStartSession(true);
            dispatch(botActions.initClientDone());
        }
    },[readyState])


    const getClient = useCallback(() => {
        dispatch(botActions.getClient());
    }, [])

    const clickButton = useCallback((text, msg_id) => {
        console.log('clickButton text: ', text)
        console.log('clickButton msg_id: ', msg_id)
        const data = {
            'msg_id': msg_id,
            'type_message': 'button',
            'text': text
        }
        sendMessage(JSON.stringify(data));
        dispatch(botActions.waitMessage());
    }, [])

    const clickMainMenu = useCallback((text) => {
        console.log('clickButton text: ', text)
        const data = {
            'type_message': 'text',
            'text': text
        }
        dispatch(botActions.showMainMenu())
        sendMessage(JSON.stringify(data));
    }, [])



    const handleClickSendMessage = useCallback(({typeSearch, text}) => {
        const data = {
            'type_search': typeSearch,
            'type_message': 'text',
            'text': text
        }
        console.log("data: ", data)
        sendMessage(JSON.stringify(data))
        // setMessage(text)
        dispatch(botActions.showSubMenu())
        dispatch(botActions.waitMessage());
    }, [])

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    // const test = useCallback(() => dispatch(botActions.closeClient(botSessionUUID)), [botSessionUUID]);


    useEffect(() => {
        return () => {
            if (!!botSessionUUID) {
                dispatch(botActions.closeClient(botSessionUUID));
            }
        };
    }, [startSession]);



    return (
        <div>
            {loading &&
                <h3>Запрос отправлен. Ожидайте ответа....</h3>
            }
            {(!showMainMenu && !showSubMenu) &&
                <Button type="submit" additionalCssClass="_addToList" onClick={()=>getClient()} >Подключиться к боту</Button>
            }
            {showMainMenu && <MainMenu
                sendMessage={handleClickSendMessage}
            />}
            {(showSubMenu && lastMessage !== null) &&
                <SubMenu
                    clickButton={clickButton}
                    clickMainMenu={clickMainMenu}
                    sendText={clickMainMenu}
                    sendMessage={handleClickSendMessage}
                />
            }

        </div>
    )
}


export default BotClient;