import axios from "axios";
import {getAxios} from "../../../helpers/axios_helpers";
import { getCookie } from 'react-use-cookie';

export const indexAPI = {
    logout,
}

async function logout(data) {
    const resp = await axios({
        ...getAxios,
        url: getAxios.url + 'logout/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    })
    return resp
}