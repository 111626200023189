import {call, put, takeEvery} from "redux-saga/effects";
import {Types} from "./action_types";
import {rfpiAPI} from "./api";
import {transportAPI} from "../../Transport/services/transport_api";
import {cabTypes} from "../../../pages/services/cabinet/cabinet_action_types";

function* rfpiSearchSaga(action) {
    yield put({
        type: Types.RFPI_SEARCH_START,
    })
    try {
        const resp = yield call(rfpiAPI.search, action.searchParams, action.page);

        yield put({
            type: Types.RFPI_SEARCH_SUCCESS,
            foundObjects: resp.data,
        });
    } catch(error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка поиска. ' + message
        });
        yield put({
            type: Types.RFPI_SEARCH_ERROR,
            error: error
        });
    }
}

function* getRFPIDetail(action) {
    yield put({
        type: Types.GET_RFPI_DETAIL_START,
    })
    try {
        const resp = yield call(rfpiAPI.getDetail, action.id)

        yield put({
            type: Types.SHOW_RFPI_DETAIL,
            objectData: resp.data
        })
    } catch (error) {
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения данных объекта. ' + message
        });
        yield put({
            type: Types.GET_RFPI_DETAIL_ERROR,
            error: error
        });

    }
}

// function* saveRequest(action) {
//     yield put({
//         type: Types.SAVE_TRANSPORT_REQUEST_START,
//     })
//     try{
//         const resp = yield axios({
//             ...postAxiosAPI,
//             url: postAxiosAPI.url + 'transport/save_request_made/',
//             data: qs.stringify({'request_object_id': action.id})})
//         if (isDebug) {
//             console.log(resp.statusText)
//         }
//         yield put({
//             type: Types.SAVE_TRANSPORT_REQUEST_SUCCESS,
//         });
//     }catch(error){
//         yield put({
//             type: Types.SAVE_TRANSPORT_REQUEST_ERROR,
//             error: error
//         });
//     }
// }

function* getRequestsSaga(action) {
    yield put({
        type: Types.GET_RFPI_REQUESTS_START,
    })
    try{
        const resp = yield call(rfpiAPI.getRequests, action.id)
        yield put({
            type: Types.SHOW_RFPI_REQUESTS,
            requests: resp.data
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка получения списка запросов. ' + message
        });
        yield put({
            type: Types.GET_RFPI_REQUESTS_ERROR,
            error: error
        });
    }
}

function* addInStorgeListsSaga(action) {

    try{
        yield call(transportAPI.addInStorgeList, action.id)
        yield put({
            type: Types.ADD_IN_RFPI_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка добавления в сторжевой лист. ' + message
        });
        yield put({
            type: Types.ADD_IN_RFPI_STORGE_LIST_ERROR,
            error: error
        });
    }
}

function* deleteFromStorgeListsSaga(action) {

    try{
        yield call(transportAPI.deleteFromStorgeList, action.id)
        yield put({
            type: Types.DELETE_FROM_RFPI_STORGE_LIST_SUCCESS,
        });
        yield put({
            type: cabTypes.GET_STORGE_LIST,
        });
    }catch(error){
        let message = ""
        if (!!error.response.data.error){
            message = error.response.data.error
        } else {
            message = error.message

        }
        yield put({
            type: cabTypes.SHOW_POPUP_ERROR,
            message: 'Ошибка удаления из сторжевого листа. ' + message
        });
        yield put({
            type: Types.DELETE_FROM_RFPI_STORGE_LIST_ERROR,
            error: error
        });
    }
}


export function* rfpiSagas() {
    yield takeEvery(Types.RFPI_SEARCH, rfpiSearchSaga);
    yield takeEvery(Types.GET_RFPI_DETAIL, getRFPIDetail);
    // yield takeEvery(Types.CLOSE_RFPI_DETAIL, saveRequest);
    yield takeEvery(Types.GET_RFPI_REQUESTS, getRequestsSaga);
    yield takeEvery(Types.ADD_IN_RFPI_STORGE_LIST, addInStorgeListsSaga);
    yield takeEvery(Types.DELETE_FROM_RFPI_STORGE_LIST, deleteFromStorgeListsSaga);
}