import React from "react";
import {connect} from "react-redux";
import {setActiveCabinet} from "./services/user_profile_actions";
import {InfoBox} from "../../ui/InfoBox/InfoBox"
import {Icon} from "../../ui/Icons/Icon";
import "./PrivateCabinet.module.css";
import PopupWindow from "../../ui/PopupWindow/PopupWindow";
import {Button} from "../../ui/Button/Button";
import {approveUserInfo} from "../../pages/services/cabinet/cabinet_actions";


function PrivateCabinet (props) {

    return (
    <div>
        {(props.askApproveUserData && !props.delayApproveUserData) && <PopupWindow
            content={"Подтвердите акктуальность выших контактных данных указанных в личном кабинете."}
            >
            <div className="mb-2">
            <Button
                onClick={()=>props.approveUserData(false)}
            >Подтвердить</Button>
            </div>
            <div>
            <Button
                onClick={()=>props.approveUserData(true)}
            >Отложить</Button>
            </div>

        </PopupWindow>
            }
        <div key="" className="personalInfo">
          <div>{props.user.full_name}</div>
          <div className="personalInfoContact">
            <Icon style={{ marginRight: "4px" }} type="envelope" />
            {props.user.email}
          </div>
        </div>
        <div className="modulesList">
          {Object.keys(props.cabinets).map((key) => (
            <InfoBox key={`key-${props.cabinets[key].id}`} item={props.cabinets[key]} onClick={props.onClick} />
          ))}
        </div>
      </div>
    );
};




function mapStateToProps(state) {
    return {
        showAllItems: state.userProfile.showAllItems,
        user: state.index.userInfo,
        cabinets: state.cabinet.cabinets,
        askApproveUserData: state.cabinet.askApprovalUserData,
        delayApproveUserData: state.cabinet.delayApprovedUserData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setActiveCabinet: (cabinet) => dispatch(setActiveCabinet(cabinet)),
        approveUserData: (delay) => dispatch(approveUserInfo(delay))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateCabinet);

