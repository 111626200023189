export const Types = {
    GET_CLIENT: 'GET_CLIENT',
    GET_CLIENT_SUCCESS: 'GET_CLIENT_SUCCESS',
    CLIENT_IS_BUSY: 'CLIENT_IS_BUSY',

    START_CLIENT_INITIALIZATION: 'START_CLIENT_INITIALIZATION',
    CLIENT_INITIALIZATION_ERROR: 'CLIENT_INITIALIZATION_ERROR',
    CLIENT_IS_INITIALIZED: 'CLIENT_IS_INITIALIZED',

    CLOSE_CLIENT: 'CLOSE_CLIENT',

    SHOW_MAIN_MENU: 'SHOW_MAIN_MENU',
    SHOW_SUB_MENU: 'SHOW_SUB_MENU',
    RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
    WAIT_MESSAGE: 'WAIT_MESSAGE',
}