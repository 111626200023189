import React from 'react';
import {SbsItem} from "./sbsItem";
import {Results} from "./Results";
import {RFPIItem} from "./rfpiItem";
import {connect} from "react-redux";
import './StorgeList.module.css'
import {cabinetActions} from "../../pages/services/cabinet/cabinet_actions";
import {TransportItem} from "./transportItem";
import {sbsActions} from "../SBS/services/sbs_actions";
import {transportActions} from "../Transport/services/transport_actions";
import {rfpiActions} from "../RFPI/service/action";


function StorageListCommon(props) {
    return (
        <Results count={`Организаций добавлено: ${props.storgeList.total_count}`} title="Сторожевой лист">
            <hr/>
            {!!props.storgeList.sbs.length &&
                <div className="modulesStorgeList">
                    <div className="sectionName">
                        <h5>СБС:</h5>
                    </div>
                    <div className="items">
                        {!!props.storgeList.sbs.length && props.storgeList.sbs.map((item, index) =>
                            <SbsItem
                                key={index}
                                item={item}
                                onClick={props.sbsClick}
                                onDelete={props.deleteFromSbsStorgeList}
                            />
                        )
                        }
                    </div>
                </div>
            }
            {!!props.storgeList.rfpi.length &&
                <div className="modulesStorgeList">
                    <div className="sectionName">
                        <h5>РФПИ:</h5>
                    </div>
                    <div className="items">
                        {props.storgeList.rfpi.map((item, index) =>
                            <RFPIItem
                                key={index}
                                item={item}
                                onClick={props.rfpiClick}
                                onDelete={props.deleteFromRFPIStorgeList}
                            />
                        )
                        }
                    </div>
                </div>
            }
            {!!props.storgeList.transport.length &&
                <div className="modulesStorgeList">
                    <div className="sectionName">
                        <h5>Транспорт:</h5>
                    </div>
                    <div className="items">
                        {!!props.storgeList.transport.length && props.storgeList.transport.map((item, index) =>
                            <TransportItem
                                key={index}
                                item={item}
                                onClick={props.transportClick}
                                onDelete={props.deleteFromTransportStorgeList}
                            />
                        )
                        }
                    </div>
                </div>
            }
        </Results>
    )

}

function mapStateToProps(state) {
    return {
        showDetail: state.cabinet.showDetail,
        loading: state.cabinet.loading,
        storgeList: state.cabinet.storgeList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sbsClick: (id) => dispatch(cabinetActions.sbsClick(id)),
        rfpiClick: (id) => dispatch(cabinetActions.rfpiClick(id)),
        transportClick: (id) => dispatch(cabinetActions.transportClick(id)),
        deleteFromSbsStorgeList: (id) => dispatch(sbsActions.deleteFromStorgeList(id)),
        deleteFromRFPIStorgeList: (id) => dispatch(rfpiActions.deleteFromStorgeList(id)),
        deleteFromTransportStorgeList: (id) => dispatch(transportActions.deleteFromStorgeList(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorageListCommon);