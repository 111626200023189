import axios from "axios";
import {deleteAxiosAPI, getAxiosAPI, postAxiosAPI} from "../../../helpers/axios_helpers";
import qs from 'qs';
import { getCookie } from 'react-use-cookie';


export const checksAPI = {
    search: postSearchRequest,
    getDetail: getDetailRequest,
    saveRequest,
    addInStorgeList,
    deleteFromStorgeList,
}


async function postSearchRequest(searchParams) {
    let params = '?';
    for (const [key, value] of Object.entries(searchParams)) {
        params = params + `${key}=${value}&`
    }
    params = params.slice(0,-1);

    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'checks/' + params,
        data: qs.stringify(searchParams),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function saveRequest(id) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'checks/info_request/',
        data: qs.stringify({
            'request_object_id': id
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function getDetailRequest(id){
    const resp = await axios({
        ...getAxiosAPI,
        url: getAxiosAPI.url + 'checks/'+ id + '/',
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function addInStorgeList(entityID) {
    const resp = await axios({
        ...postAxiosAPI,
        url: postAxiosAPI.url + 'checks/tracked_list/',
        data: qs.stringify({
            'tracked_object': entityID
        }),
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

async function deleteFromStorgeList(entityID) {
    const resp = await axios({
        ...deleteAxiosAPI,
        url: deleteAxiosAPI.url + `checks/tracked_list/${entityID}/`,
        headers: {
            Accept: 'application/json',
            'Authorization': 'Token ' + getCookie('token')
        },
    });
    return resp
}

