export const SEARCH_MODES =  {

    'FIO': {
        searchLabel: 'ФИО собственника',
        fields: [{
            name: 'last_name',
            placeholder: 'Фамилия',
            title: 'Фамилия',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'first_name',
            placeholder: 'Имя',
            title: 'Имя',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'middle_name',
            placeholder: 'Отчество',
            title: 'Отчество',
            typeInput: 'text',
            required: true,
            value: '',
        },{
            name: 'birth_date',
            placeholder: '01.01.2000',
            title: 'Дата рождения',
            typeInput: 'date',
            required: true,
            value: '',
        }
        ]
    }
}