
import React from "react";
import {connect} from "react-redux";

/**
 * @typedef PropType
 */

/**
 * @param {PropType} props
 * @returns {JSX.Element}
 * @constructor
 */

function ContentPage (props) {
    if (!props.onSideBar) {
        const ContentItem = props.cabinets[props.activeWindow].component
        return <ContentItem onClick={props.onClick}/>
    } else {
        const ContentItem = props.sidebarMenuItems[props.activeWindow].component
        return <ContentItem onClick={props.onClick}/>
    }
}


function mapStateToProps(state) {
    return {
        activeWindow: state.cabinet.activeWindow,
        cabinets: state.cabinet.cabinets,
        error: state.cabinet.error,
        sidebarMenuItems: state.cabinet.sidebarMenuItems,
        onSideBar: state.cabinet.onSideBar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onClick: (func)=> dispatch(func())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);