import {
   checksTypes
} from "./action_types";


const initialState = {
    showDetail: false,
    objectData: null,
    foundObjects: null,
    sendRequest: false,
    loading: false,
    error: null,
    searchParams: null,
    storgeList: null,
    updateSearch: false,

    foundPage: null,
    maxFoundPage: null,

}


const handlers = {
    [checksTypes.SAVE_CHECKS_REQUEST_START]: (state, action) =>({...state, loading: false}),
    [checksTypes.SAVE_CHECKS_REQUEST_SUCCESS]: (state, action)=>({...state, loading: false}),
    [checksTypes.SEND_CHECKS_REQUEST]: (state, action) =>({...state, loading: true, foundObjects: null}),

    [checksTypes.SHOW_CHECKS_DETAIL_START]: (state, action)=>({...state, showError:false, error: action.error}),
    [checksTypes.SHOW_CHECKS_DETAIL_SUCCESS]: (state, action) =>({...state, loading: false, showDetail: true, objectData: action.objectData}),
    [checksTypes.SHOW_CHECKS_DETAIL_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),
    [checksTypes.CLOSE_CHECKS_DETAIL]: (state, action)=>({...state, showDetail: false}),

    [checksTypes.SEARCH_CHECKS_START]: (state, action) =>({...state, loading: true, error: null}),
    [checksTypes.SEARCH_CHECKS_SUCCESS]: (state, action)=>({...state, loading: false, foundObjects: action.foundObjects}),
    [checksTypes.SEARCH_CHECKS_ERROR]: (state, action)=>({...state, showError:true, error: action.error}),


    DEFAULT: state => state
}

export function checksReducer(state=initialState, action) {

    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}