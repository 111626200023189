import React, {useCallback, useState} from "react";
import {MainTitle} from "../ui/Typography/Typography";
import {Input} from "../ui/Input/Input";
import {Button} from "../ui/Button/Button";
import {connect} from "react-redux";
import {sendToSupport} from "../pages/services/cabinet/cabinet_actions"

/**
 * @typedef SupportPropsType
 * @property {JSX.Element | text} children
 */

/**
 *
 * @param {SupportPropsType} props
 * @returns {JSX.Element}
 */
function Support(props) {
    const [topic, setTopic] = useState("");
    const [message, setMessage] = useState("");


    const onTopicChange = useCallback(
        (e) => {
            setTopic(e.target.value);
        },
        [setTopic]
    );

    const onMessageChange = useCallback(
        (e) => {
            setMessage(e.target.value);
        },
        [setMessage]
    );

    const onSubmit = (e) => {
        e.preventDefault();
        setTopic('');
        setMessage('');
        props.sendToSupport(topic, message)
    }

    return (
        <div>
            <MainTitle>Запрос в поддержку</MainTitle>
            <form className="Form" onSubmit={onSubmit}>
                <fieldset className="FormFieldSet">
                    <Input
                        value={topic}
                        placeholder="Тема запроса"
                        onChange={onTopicChange}
                        type="text"
                    />
                </fieldset>
                <fieldset className="FormFieldSet _wide">
          <textarea className="FormTextarea"
                    placeholder="Ваш запрос" onChange={onMessageChange} value={message}/>
                </fieldset>
                <footer className="FormFooter">
                    <Button type="submit">отправить</Button>
                </footer>
            </form>
        </div>
    );
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendToSupport: (topic, message) => dispatch(sendToSupport(topic, message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);