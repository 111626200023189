import React from 'react';
import {connect} from "react-redux";

import TransportSearchForm from './transport_search';
import {transportActions} from "./services/transport_actions";
import {TransportDetails} from "./transport_detail";


class Transport extends React.Component {
    renderSearchTab = () => {
        if (this.props.showDetail) {
            return <TransportDetails
                onClose={this.props.closeDetail}
                objectData={this.props.objectData}
                addInStorgeList={this.props.addInStorgeList}
                deleteFromStorgeList={this.props.deleteFromStorgeList}
                storgeList={this.props.storgeList}
            />;
        } else {
            return <TransportSearchForm />;
        }
    }


    render() {
        return (this.renderSearchTab())
    }
}

function mapStateToProps(state) {
    return {
        showDetail: state.transport.showDetail,
        objectData: state.transport.objectData,
        foundObjects: state.transport.foundObjects,
        storgeList: state.transport.storgeList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: () => dispatch(transportActions.closeDetail()),
        addInStorgeList: (id) => dispatch(transportActions.addInStorgeList(id)),
        deleteFromStorgeList: (id) => dispatch(transportActions.deleteFromStorgeList(id)),
        //TODO getPDF()
        //TODO
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transport);